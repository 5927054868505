<script setup>
  import { version } from '@/common/version';
  import {computed, ref, watch} from "vue";
  import {adjustAdminPath, isAdminAllowed, isAdministrationPage} from "@/common/admin";
  import {useRoute, useRouter} from "vue-router";
  import {useI18n} from "vue-i18n";
  import configs from "@/configs";
  import axios from "axios";
  import storeHelper from "@/store";
  import {useStore} from "vuex";

  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const { t } = useI18n();
  const versionRef = ref(version);

  const isAdmin = ref(store.getters.isAdmin);
  console.log(`is administrator: ${JSON.stringify(isAdmin.value)}`);

  const adminAllowed = ref(isAdminAllowed());
  console.log(`adminAllowed: ${adminAllowed.value}`);
  const adminOn = ref(isAdministrationPage() && isAdminAllowed());
  console.log(`adminOn: ${adminOn.value}`);

  watch(adminOn, (newValue) => {
    const currentPath = route.path;

    const newPath = adjustAdminPath(currentPath, newValue);
    if (newPath !== currentPath) {
      router.push({
        path: newPath,
        query: route.query,
      }).then(() => {
        router.go(0);
      });
    }
  });

  let component = computed(() => {
    const path = route.path;
    console.log(`path to component: path = ${path}`);
    if (route.path.includes("trending")) {
      return "trendingnews";
    } else {
      return "encyclopedia";
    }
  })

  const articlesPath = computed(() => ('/articles'));
  const charactersPath = computed(() => (adminOn.value ? '/characters_admin' : '/characters'));
  const trendingPath = computed(() => (adminOn.value ? '/trending_admin' : '/trending'));
  const schedulePath = computed(() => (adminOn.value ? '/schedule_admin' : '/schedule'));

  const cloudFuncVer = ref(t('message.commonVerQuerying'));
  const cloudRunVer = ref(t('message.commonVerQuerying'));
  const apiVer = ref(t('message.commonVerQuerying'));

  const getCloudFuncVersion = async function() {
    let urlPrefix = configs.cloudFunctionPublicPrefix;
    console.log(`urlPrefix: ${urlPrefix}`);

    let url = urlPrefix + `/v1/version?comp=cloudfunc`;
    console.log(`url: ${url}`);

    try {
      let ret = await axios.get(url, {
      });

      if (ret && ret.data && ret.data.code === 200) {
        if (ret.data.versions && ret.data.versions.length > 0) {
          cloudFuncVer.value = ret.data.versions[0];
        }
      } else {
        console.error(`failed to query Cloud Func version api: ${JSON.stringify(ret)}`);
      }
    } catch (e) {
      console.error(`failed to query Cloud Func version api: ${e}`);
    }
  }

  const getCloudRunVersion = async function(component) {
    let urlPrefix = configs.cloudFunctionPublicPrefix;
    console.log(`urlPrefix: ${urlPrefix}`);

    let url = urlPrefix + `/v1/version?comp=${component}`;
    console.log(`url: ${url}`);

    try {
      let ret = await axios.get(url, {
      });

      if (ret && ret.data && ret.data.code === 200) {
        if (ret.data.versions && ret.data.versions.length > 0) {
          cloudRunVer.value = ret.data.versions[0];
        }
      } else {
        console.error(`failed to query Cloud Run version api: ${JSON.stringify(ret)}`);
      }
    } catch (e) {
      console.error(`failed to query Cloud Run version api: ${e}`);
    }
  }

  const getAPIVersion = async function() {
    let urlPrefix = configs.apiUrlPrefix;
    console.log(`urlPrefix: ${urlPrefix}`);

    let url = urlPrefix + `/v1/creativepulse/version`;
    console.log(`url: ${url}`);

    try {
      let ret = await axios.get(url, {
      });

      if (ret && ret.data && ret.data.code === 200) {
        if (ret.data.version) {
          apiVer.value = ret.data.version;
        }
      } else {
        console.error(`failed to query API version api: ${JSON.stringify(ret)}`);
      }
    } catch (e) {
      console.error(`failed to query API version api: ${e}`);
    }
  }

  const token = computed(() => storeHelper.getters.token);
  const signedIn = computed(() => token.value !== null)
  const signActionLabel = ref("")

  console.log(`signedIn: ${signedIn.value}`);
  if (signedIn.value) {
    signActionLabel.value = t('message.commonActionSignOut')
  } else {
    signActionLabel.value = t('message.commonActionSignIn')
  }

  watch(signedIn, (newValue) => {
    console.log(`signedIn changed to: ${newValue}`);
    if (newValue) {
      signActionLabel.value = t('message.commonActionSignOut')
    } else {
      signActionLabel.value = t('message.commonActionSignIn')
    }
  });

  let signInOrOut = async function() {
    console.log(`signed in: ${signedIn.value}`);
    if (signedIn.value) {
      await store.dispatch('signOut');
      window.location.reload();
    } else {
      router.push({
          path: '/login',
          query: {
            redirect: route.fullPath
        }
      })
    }
  }

  getCloudRunVersion(component.value);
  getCloudFuncVersion();
  getAPIVersion();
</script>

<template>
  <v-navigation-drawer>
    <v-list-item
        class="navi-title"
        :subtitle="versionRef">
      <template v-slot:title>
        <span class="navi-title-p1">{{ $t('message.navigationTitleEncyclopediaPart1') }}</span>
        <span>{{ $t('message.navigationTitleEncyclopediaPart2') }}</span>
      </template>
      <template v-slot:prepend>
        <v-img
            width="48"
            src="@/assets/8656332_medium_forum_blog_application_website_icon.svg"
        ></v-img>
      </template>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item
        class="navi-comp"
        router
        link
        :to="articlesPath"
        :title="$t('message.navigationPageArticles')">
      <template v-slot:prepend>
        <v-img
            width="24"
            src="@/assets/9023142_article_medium_fill_icon.svg"
        ></v-img>
      </template>
    </v-list-item>
    <v-list-item
        class="navi-comp"
        router
        link
        :to="charactersPath"
        :title="$t('message.navigationPageCharacters')">
      <template v-slot:prepend>
        <v-img
            width="24"
            src="@/assets/211793_people_icon.svg"
        ></v-img>
      </template>
    </v-list-item>
    <v-list-item
        v-show="isAdmin"
        class="navi-comp"
        router
        link
        :to="trendingPath"
        :title="$t('message.navigationPageTrendingNews')">
      <template v-slot:prepend>
        <v-img
            width="24"
            src="@/assets/8673638_ic_fluent_news_filled_icon.svg"
        ></v-img>
      </template>
    </v-list-item>
    <v-list-item
        v-show="isAdmin"
        class="navi-comp"
        router
        link
        :to="schedulePath"
        :title="$t('message.navigationPageSchedules')">
      <template v-slot:prepend>
        <v-img
            width="24"
            src="@/assets/623083_calendar_date_event_ical_plan_icon.svg"
        ></v-img>
      </template>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
      <v-row style="margin: 0">
        <v-spacer></v-spacer>
        <v-btn
            variant="outlined"
            v-on:click="signInOrOut()">
          {{ signActionLabel }}
          <template v-slot:prepend >
            <v-img v-show="isAdmin" width="16" src="@/assets/728988_chess_crown_king_award_prize_icon.svg"/>
          </template>
        </v-btn>
        <v-spacer></v-spacer>
      </v-row>
    </v-list-item>
    <template v-slot:append>
      <div class="backend-info">
        <span class="section-title">Powered by</span>
        <v-row
            justify="center"
            align-content="center"
        >
          <v-col
              class="component"
              cols="2"
              md="2"
          >
            <v-img width="32" src="@/assets/9080211_api_icon.svg" ></v-img>
          </v-col>
          <v-col
              class="component comp-version"
              cols="4"
              md="4"
          >
            <div>{{ apiVer }}</div>
          </v-col>
          <v-col
              class="component"
              cols="2"
              md="2"
          >
            <v-img width="32" src="@/assets/4373765_docker_logo_logos_icon.svg" ></v-img>
          </v-col>
          <v-col
              class="component comp-version"
              cols="4"
              md="4"
          >
            <div>{{ cloudRunVer }}</div>
          </v-col>
          <v-spacer></v-spacer>

        </v-row>
        <v-row>
          <v-col
              class="component"
              cols="2"
              md="2"
          >
            <v-img width="28" src="@/assets/firebase_new_logo.svg" ></v-img>
          </v-col>
          <v-col
              class="component comp-version"
              cols="4"
              md="4"
          >
            <div>{{ cloudFuncVer }}</div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </div>
      <v-divider></v-divider>
      <v-container>
        <v-col id="copyright"
               class="text-center"
               cols="12"
        >
          {{ $t("message.commonFooterCopyright") }} {{ new Date().getFullYear() }} &#169;
        </v-col>
      </v-container>
    </template>
  </v-navigation-drawer>
</template>

<style scoped>
.v-list-item {
  text-align: left;
  padding: 16px;
}

.navi-title :deep(.v-list-item-title) {
  font-weight: bold;
  font-size: 18px;
}

.navi-title .v-img {
  margin-right: 16px;
}

.navi-title-p1 {
  color: #FF6600;
}

.navi-comp .v-img {
  margin-right: 12px;

}

.backend-info {
  text-align: left;
  margin: 8px;
}

.backend-info .v-row {
  margin: 0;
}

.section-title {
  font-size: 14px;
  font-weight: bold;
  margin: 16px 8px;
}

.component {
  padding: 8px 8px 8px 8px;
}

.compo-title {
  font-size: 14px;
  padding: 0;
}

.comp-version {
  padding-left: 0;
}

.comp-version div {
  font-size: 14px;
  font-style: italic;
  display: flex;
  align-items: center; /* Vertically centers the text */
  height: 100%;
  padding: 0;
}
</style>
