import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import configs from "@/configs";

// Initialize Firebase
const app = initializeApp(configs.firebaseConfig);
const analytics = getAnalytics(app);

export function getFirebaseApp() {
    return app;
}
