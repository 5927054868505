<script setup>
import configs from "@/configs";
import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import { useI18n } from 'vue-i18n';
import {computed, ref, watch} from "vue";
import store from "@/store";
import * as adminCommon from "@/common/admin";
import Navigation from "@/components/Navigation.vue";
import BottomLine from "@/components/BottomLine.vue";
import {has} from "markdown-it/lib/common/utils.mjs";

// Use i18n
const router = useRouter();
const route = useRoute();
const { t } = useI18n();

let fullPath = route.fullPath;
let query = route.query;
let theme= 'light';
let locale= 'en';
let docHeight= document.documentElement.clientHeight;
let displayHeight= document.documentElement.clientHeight;
let showFooter = true;
let errorMessage = null
let style= '0';

let trendingNews = [];
let pageSize = 5;
let pageKey = null;
let noMoreElements = false;

const token = computed(() => store.getters.token);
console.log(`token: ${JSON.stringify(token.value)}`);

const isAdmin = computed(() => store.getters.isAdmin);

const pickingSource = ref(route.query.pickingSource || false);
console.log(`pickingSource: ${JSON.stringify(pickingSource.value)}`);
if (pickingSource.value === true) {
  trendingNews.push({
    id: "random",
    title: t('message.commonPickingSourceRandomTitle'),
    abstract: t('message.commonPickingSourceRandomAbstract'),
  });
}

let cnIPS = ref(null);
let cnIPSEnabled = ref(false);
const cnIPSMin = 1;
const cnIPSMax = 10;
watch(cnIPSEnabled, (enabled) => {
  if (enabled) {
    cnIPS.value = Math.round((cnIPSMax - cnIPSMin) / 2) + cnIPSMin;
  } else {
    cnIPS.value = null;
  }
});

const cnIPSThumb = computed(() => {
  if (cnIPSEnabled.value) {
    return "always"
  } else {
    return false;
  }
});

const siteThePager = {
  value: "https://thepaper.cn",
  title: t('message.pageTrendingSourceTitleThePaper')
};
const siteToutiao = {
  value: "https://www.toutiao.com",
  title: t('message.pageTrendingSourceTitleToutiao')
};
const siteChinaNews = {
  value: "https://www.chinanews.com/scroll-news/news1.html",
  title: t('message.pageTrendingSourceTitleChinaNews')
};
const siteRedditAfrica = {
  value: "https://www.reddit.com/r/Africa/top/?t=day",
  title: t('message.pageTrendingSourceTitleRedditAfrica')
};
const siteBBCSport = {
  value: "https://www.bbc.com/sport/olympics",
  title: t('message.pageTrendingSourceTitleBBCSport')
};
const siteBBC = {
  value: "https://www.bbc.com/",
  title: t('message.pageTrendingSourceTitleBBC')
};
const siteCNN = {
  value: "https://edition.cnn.com/",
  title: t('message.pageTrendingSourceTitleCNN')
};
const siteGooglePlayApps = {
  value: "https://play.google.com/store/apps",
  title: t('message.pageTrendingSourceTitleGooglePlayApps')
};
const siteGooglePlayGames = {
  value: "https://play.google.com/store/games",
  title: t('message.pageTrendingSourceTitleGooglePlayGames')
};
const pre_defined_sites = [
  siteThePager, siteToutiao, siteChinaNews,
  siteRedditAfrica, siteBBCSport, siteBBC, siteCNN,
  siteGooglePlayApps, siteGooglePlayGames
]
const defaultSites = [
]
let cnSites = ref(null);
let cnSitesEnabled = ref(false);
let newSite = ref(null)
watch(cnSitesEnabled, (enabled) => {
  if (enabled) {
    cnSites.value = [...defaultSites];
  } else {
    cnSites.value = null;
    newSite.value = null;
  }
});

let siteIcon = function (item) {
  if (cnSites.value && cnSites.value.some(selected => selected.value === item.value)) {
    return 'custom:checkIcon';
  }

  return 'custom:uncheckIcon';
}

let toggleSite = function (item) {
  console.log(`toggle item: ${JSON.stringify(item)}`)
  console.log(`cnSits: ${JSON.stringify(cnSites.value)}`)
  if (!cnSites.value) {
    cnSites.value = [];
  }

  const index = cnSites.value.findIndex(
      selected => selected.value === item.value
  );

  console.log(`item index: ${index}`);

  if (index === -1) {
    cnSites.value.push(item);
  } else {
    cnSites.value.splice(index, 1);
  }
}

const defaultPages = [];
let cnPages = ref(defaultPages);
let cnPagesEnabled = ref(false);
let newPage = ref(null)
watch(cnPagesEnabled, (enabled) => {
  if (enabled) {
    cnPages.value = [];
  } else {
    cnPages.value = null;
    newPage.value = null;
  }
});

let selectNews = function (article) {
  if (!pickingSource.value) {
    console.log(`not pick source, do nothing.`);
    return;
  }

  console.log(`selected news: ${JSON.stringify(article)}`);

  const path = sessionStorage.getItem('redirect');

  console.log(`redirect: ${JSON.stringify(path)}`);

  sessionStorage.setItem('selectedId', article.id);
  sessionStorage.setItem('selectedTitle', article.title);
  sessionStorage.setItem('selectedAbstract', article.abstract);

  router.push(path);
}

let cnCoverImageStrategy = ref(null);
let cnCoverImageStrategyEnabled = ref(false);
watch(cnCoverImageStrategyEnabled, (enabled) => {
  if (enabled) {
    cnCoverImageStrategy.value = "random";
  } else {
    cnCoverImageStrategy.value = null;
  }
});

watch(cnCoverImageStrategy, (strategy) => {
  if (strategy === "specified") {
    cnCoverImageIndex.value = 0
  }
});
let cnCoverImageIndex = ref(0)

let hasGooglePlaySources = function () {
  if (cnSites.value) {
    for (let site of cnSites.value) {
      if (site.value.includes("play.google.com")) {
        return true
      }
    }
  }

  if (cnPages.value) {
    for (let page of cnPages.value) {
      if (page.includes("play.google.com")) {
        return true
      }
    }
  }

  return false;
}

const infiniteKey = ref(0);

const forceRender = () => {
  infiniteKey.value += 1;
};

let providers = [
  { name: null, label: t('message.pageTrendingNewsProviderAll') },
  { name: "BBC News", label: t('message.pageTrendingSourceTitleBBC') },
  { name: "CNN News", label: t('message.pageTrendingSourceTitleCNN') },
  { name: "Google Play", label: t('message.pageTrendingSourceTitleGooglePlay') },
  { name: "Toutiao.com", label: t('message.pageTrendingSourceTitleToutiao') },
];

const accProvidersProp =  sessionStorage.getItem('acceptProviders');
const acceptedProviders = accProvidersProp ? accProvidersProp.split(',').map(provider => provider.trim())  : [];
console.log(`[PF] accept: ${acceptedProviders}`)

providers = providers.filter(provider => {
  let ret = provider.name === null || acceptedProviders.length === 0 || acceptedProviders.includes(provider.name)
  console.log(`[PF] provider: ${JSON.stringify(provider)} [ret = ${ret}]`);
  return provider.name === null || acceptedProviders.length === 0 || acceptedProviders.includes(provider.name)
});

console.log(`[PF] providers: ${JSON.stringify(providers)}`)


let provider = null;

let chipVariant = function (chip) {
  if (chip.name === provider) {
    return "flat"
  } else {
    return "tonal"
  }
};

let listTrendingNewsByProvider = async function(p) {
  console.log(`list trending news by provider: ${p}`);

  provider = p;
  trendingNews = [];
  noMoreElements = false;
  pageKey = null;

  // this.$emit('load', {side: 'end'})
  forceRender()
}

let listTrendingNews =  async function ({ done }) {
  console.log(`list trending news: no more = ${noMoreElements}`);
  if (noMoreElements) {
    done('empty')
    return
  }
  let urlPrefix = configs.apiUrlPrefix;
  console.log(`urlPrefix: ${urlPrefix}`);

  let url = urlPrefix + `/v1/creativepulse/trending`;

  url +=`?page_size=${pageSize}`
  if (pageKey) {
    url += `&page_key=${pageKey}`
  }

  if (provider) {
    url += `&providers=${provider}`;
  } else {
    if (acceptedProviders.length > 0) {
      url += `&providers=${acceptedProviders.join(',')}`;
    }
  }

  console.log(`url: ${url}`);

  try {
    let ret = await axios.get(url, {
    });

    if (ret && ret.data && ret.data.code === 200) {
      console.log(`ret: [${JSON.stringify(ret.data)}]`);

      for (let i = 0; i < ret.data.news.length; i++){
        let item = ret.data.news[i]
        item.expand = false
        trendingNews.push(item)
      }

      pageKey = ret.data.nextPage
      if (ret.data.nextPage) {
        console.log('status: ok')
        done('ok')
      } else {
        console.log('status: empty')
        done('empty')
        noMoreElements = true
      }
    } else {
      errorMessage = t('message.errorFailedToCallApi');
      done('error')
    }
  } catch (e) {
    console.error(`failed to call apply api: ${e}`);
    errorMessage = t('message.errorFailedToCallApi');
    done('error')
  }
}

let getElapseTime = function (publishedDateTime) {
  console.log(`processing datetime: ${publishedDateTime}`)
  const publishedDate = new Date(publishedDateTime);

  // Get the current date and time
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDiff = currentDate.getTime() - publishedDate.getTime();

  // Convert the time difference to hours
  const hoursDiff = timeDiff / (1000 * 60 * 60);

  // Check if the elapsed time is less than 24 hours
  if (hoursDiff < 24) {
    // Round the elapsed time to the nearest hour and return as a string
    return Math.round(hoursDiff) + t('message.pageArticlesTimeElapseHour');
  } else {
    // Calculate the elapsed time in days and return as a string
    const daysDiff = Math.floor(hoursDiff / 24);
    return daysDiff + t('message.pageArticlesTimeElapseDay');
  }
}

let collectable = computed(() => {
  console.log(`cnSites.value = ${JSON.stringify(cnSites.value)}`);
  console.log(`cnPages.value = ${JSON.stringify(cnPages.value)}`);
  return cnSites.value !== null && (cnSites.value.length > 0)
      || (cnPages.value !== null && cnPages.value.length > 0)
});
let newsCollectingExecution = ref(null);
let newsCollectingCheckHandler = null;
let executionCheckInterval = 10000;

let collectNews = async function() {
  console.log(`token: ${token.value}`)
  if (!isAdmin) {
    console.error(`no permit to run this in non-admin page.`)
    return;
  }

  let urlPrefix = configs.cloudFunctionPrefix;
  console.log(`urlPrefix: ${urlPrefix}`);

  let url = urlPrefix + `/v1/trending/collect`;
  console.log(`url: ${url}`);

  newsCollectingExecution.value = "";

  let data = {};

  let numOfItems = 0;
  const sites = cnSites.value;
  if (sites && sites.length > 0) {
    data.sites = sites.map(site => site.value).join(', ');
    numOfItems += sites.length;
  }

  const pages = cnPages.value;
  if (pages && pages.length > 0) {
    data.pages = pages.join(', ')
    numOfItems += pages.length;
  }

  if (numOfItems === 0) {
    console.error(`no items to collect.`)

    return;
  }

  if (cnIPS.value) {
    data.count = cnIPS.value;
  }

  if (cnCoverImageStrategy.value) {
    if (cnCoverImageStrategy.value === 'specified') {
      data.cover_image_strategy = cnCoverImageIndex.value;
    } else {
      data.cover_image_strategy = cnCoverImageStrategy.value;
    }
  }

  console.log(`data: ${JSON.stringify(data)}`);

  try {
    let ret = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });

    if (ret && ret.data && ret.data.code === 200) {
      console.log(`collecting of trending news is requested.`);

      newsCollectingExecution.value = ret.data.execution;
      newsCollectingCheckHandler = setInterval(
          checkExecution,
          executionCheckInterval,
          newsCollectingExecution.value,
          newsCollectingDone);
    } else {
      errorMessage = t('message.errorFailedToCallApi');
      this.cleanNewsCollecting();
    }
  } catch (e) {
    console.error(`failed to call collect trending news api: ${e}`);

    errorMessage = t('message.errorFailedToCallApi');
    cleanNewsCollecting();
  }
}

let newsCollectingDone = function (status) {
  console.log(`trending news collection status: ${status}`);

  if (status === 'succeed') {
    trendingNews = [];
    noMoreElements = false;
    pageKey = null;

    // this.$emit('load', {side: 'end'})
    forceRender()
  }

  cleanNewsCollecting()
}

let cleanNewsCollecting = function () {
  if (newsCollectingCheckHandler) {
    clearInterval(newsCollectingCheckHandler)
    newsCollectingCheckHandler = null;
  }

  newsCollectingExecution.value = null;
}

let checkExecution = async function (executionId, done) {
  console.log(`checking status of execution [${executionId}].`);

  let urlPrefix = configs.cloudFunctionPrefix;
  console.log(`urlPrefix: ${urlPrefix}`);

  let url = urlPrefix + `/v1/execution/status?id=${executionId}`;
  console.log(`url: ${url}`);

  try {
    let ret = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });

    if (ret && ret.data && ret.data.code === 200) {
      console.log(`[${JSON.stringify(ret.data)}]`);
      if (ret.data.status === 'succeed' || ret.data.status === 'failed') {
        done(ret.data.status);
      }
    } else {
      errorMessage = t('message.errorFailedToCallApi');
    }
  } catch (e) {
    console.error(`failed to call get execution status api: ${e}`);
    errorMessage = t('message.errorFailedToCallApi');
  }
}

</script>

<template>
  <v-app class="oms-app">
    <Navigation v-show="!pickingSource"/>
    <v-main class="news_frame" fluid>
      <v-dialog activator="#collecting_news" max-width="340">
        <template v-slot:default="{ isActive }">
          <v-card
              :title='$t("message.pageTrendingCollectingTitle")'
          >
            <template v-slot:prepend>
              <img
                  width="32"
                  style="margin: 0 10px"
                  src="@/assets/6363079_cloud_data_download_file_save_icon.svg" />
            </template>
            <template v-slot:actions>
              <v-col>
                <v-row
                    class="empty-collection-prompt"
                    v-show="!collectable"
                    align="center"
                    justify="center">
                  <span >{{ $t('message.pageTrendingEmptySourcePrompt')}}</span>
                </v-row>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="ml-auto"
                      :disabled="!collectable"
                      :text='$t("message.pageTrendingActionCollect")'
                      @click="isActive.value = false; collectNews()"
                  ></v-btn>
                  <v-btn
                      class="ml-auto"
                      :text='$t("message.commonActionCancel")'
                      @click="isActive.value = false"
                  ></v-btn>
                </v-row>
              </v-col>
            </template>
            <v-col class="cn-customization">
              <v-checkbox
                  class="cn-option-title"
                  true-icon="custom:checkIcon"
                  false-icon="custom:uncheckIcon"
                  :label="$t('message.pageTrendingIPSLabel')"
                  v-model="cnIPSEnabled">
              </v-checkbox>
              <v-slider
                  v-model="cnIPS"
                  :disabled="!cnIPSEnabled"
                  :min="cnIPSMin"
                  :max="cnIPSMax"
                  step="1"
                  :thumb-label="cnIPSThumb"
              ></v-slider>
              <v-checkbox
                  class="cn-option-title"
                  true-icon="custom:checkIcon"
                  false-icon="custom:uncheckIcon"
                  :label="$t('message.pageTrendingLabelSites')"
                  v-model="cnSitesEnabled">
              </v-checkbox>
              <v-combobox
                  v-model="cnSites"
                  :disabled="!cnSitesEnabled"
                  :items="pre_defined_sites"
                  chips
                  multiple
              >
                <template v-slot:selection="data">
                  <v-chip
                      @click:close="removeItem(data.item)"
                  >
                    {{ data.item.title }}
                  </v-chip>
                </template>
                <template v-slot:item=" { item }">
                  <v-list-item
                      :title="item.title"
                      :subtitle="item.value"
                      @click="toggleSite(item)"
                  >
                    <template v-slot:prepend>
                      <v-icon :icon="siteIcon(item)"/>
                    </template>
                  </v-list-item>
                </template>
              </v-combobox>
              <v-checkbox
                  clearable
                  class="cn-option-title"
                  true-icon="custom:checkIcon"
                  false-icon="custom:uncheckIcon"
                  :label="$t('message.pageTrendingLabelPages')"
                  v-model="cnPagesEnabled">
              </v-checkbox>
              <v-combobox
                  v-model="cnPages"
                  :disabled="!cnPagesEnabled"
                  chips
                  multiple
              >
                <template v-slot:selection="data">
                  <v-chip
                      @click:close="removeItem(data.item)"
                  >
                    {{ data.item.title }}
                  </v-chip>
                </template>
              </v-combobox>
              <div v-show="hasGooglePlaySources()">
                <v-checkbox
                    clearable
                    class="cn-option-title"
                    true-icon="custom:checkIcon"
                    false-icon="custom:uncheckIcon"
                    :label="$t('message.pageTrendingLabelCoverImageStrategy')"
                    v-model="cnCoverImageStrategyEnabled">
                </v-checkbox>
                <v-radio-group
                    :disabled="!cnCoverImageStrategyEnabled"
                    inline
                    v-model="cnCoverImageStrategy"
                >
                  <v-radio
                      true-icon="custom:radioCheckIcon"
                      false-icon="custom:radioUncheckIcon"
                      :label="$t('message.pageTrendingLabelCoverImageStrategyRandom')"
                      value="random">
                  </v-radio>
                  <v-radio
                      true-icon="custom:radioCheckIcon"
                      false-icon="custom:radioUncheckIcon"
                      :label="$t('message.pageTrendingLabelCoverImageStrategySpecify')"
                      value="specified">
                  </v-radio>
                </v-radio-group>
                <v-text-field
                    v-show="cnCoverImageStrategy === 'specified'"
                    :label="$t('message.pageTrendingLabelCoverImageIndex')"
                    type="number"
                    v-model="cnCoverImageIndex"
                >

                </v-text-field>
              </div>
            </v-col>
          </v-card>
        </template>
      </v-dialog>
      <v-row class="provider-group"
             v-show="providers != null">
        <v-col cols="12" justify="left" align="left">
          <div class="chip-container">
            <v-chip
                v-for="chip in providers"
                :key="chip"
                class="ma-2 chip-item"
                :variant="chipVariant(chip)"
                @click="listTrendingNewsByProvider(chip.name)"
            >
              {{ chip.label }}
            </v-chip>
          </div>
        </v-col>
      </v-row>
      <v-infinite-scroll
          :items="trendingNews"
          :key="infiniteKey"
          @load="listTrendingNews">
        <template v-slot:empty>
          <BottomLine/>
        </template>
        <v-list-item
            v-for="(article, i) in trendingNews" :key="i"
            v-show="article.title !== null && article.title.length > 0"
            v-on:click="selectNews(article)"
        >
          <v-card
              class="mx-auto"
              max-width="344"
          >
            <div
                v-if="article.cover_image != null"
                class="asset-image shrink-image-container">
              <v-img class="shrink-on-hover"
                     :src="article.cover_image"
                     cover
              >
              </v-img>
            </div>
            <v-card-title> {{article.title}} </v-card-title>
            <v-card-subtitle>
              <v-row justify="start" align="start">
                <v-col
                    class="providers">
                  <span v-if="pickingSource" >{{ article.provider }}</span>
                  <a v-else :href="article.link">{{ article.provider }}</a>
                  <v-chip
                      class="reference"
                      variant="tonal"
                      size="small"
                  >
                    {{ article.ref }}
                  </v-chip>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="datetime">
                  {{ getElapseTime(article.datetime) }}
                </v-col>
              </v-row>
            </v-card-subtitle>

            <v-card-text>
              {{article.abstract}}
            </v-card-text>

            <v-row
                v-show="article.tags != null">
              <v-col cols="12" justify="left" align="left">
                <div class="chip-container">
                  <v-chip
                      v-for="chip in article.tags"
                      :key="chip"
                      class="ma-2 chip-item"
                  >
                    {{ chip }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </v-list-item>
      </v-infinite-scroll>
      <v-fab
          :active="isAdmin"
          rounded="circle"
          class="fab"
          size="48"
          id="collecting_news"
      >
        <img
            v-if="newsCollectingExecution == null"
            style="padding: 8px"
            src="@/assets/6363079_cloud_data_download_file_save_icon.svg" />
        <v-progress-circular
            v-else
            :size="25"
            :width="3"
            indeterminate
        ></v-progress-circular>
      </v-fab>
    </v-main>
  </v-app>
</template>

<style scoped>
.news_frame {
  padding: 0;
}

.v-card-title {
  text-align: left;
  font-size: 18px;
  line-height: unset !important;
  font-weight: bold;
  overflow: hidden;
  white-space: normal;
  word-wrap: normal;
}

.v-card-text {
  font-size: 14px !important;
  text-align: left;
  padding-top: 0 !important;
}

.providers {
  text-align: left;
  padding-bottom: 24px !important;
}

.reference {
  margin:0 16px;
}

.datetime {
  text-align: right;
  padding-right: 16px;
}

.chip-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.chip-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.chip-item {
  flex: 0 0 auto;
  font-size: 11px !important;
}

.v-fab {
  position: fixed;
  bottom: 64px;
  right: 72px;
}

.v-fab :deep(button) {
  border: 2px solid #3d3d3d
}

.cn-customization .v-input {
  margin: 0 16px;
}

.cn-customization .v-checkbox :deep(.v-selection-control)  {
  min-height: 20px !important;
}

.cn-customization .v-slider {
  margin: 6px 36px !important;
}

.cn-option-title {
  margin: 0 0 -16px;
}

.cn-option-title :deep(label) {
  color: black;
  opacity: unset;
  font-weight: bold;
  font-size: 18px !important;
}

.cn-option-title img {
  display: none;
  width: 32px;
  height: 32px;
}

.source-container {
  font-size: 12px;
}

.source-container .v-row {
  margin: 0;
}

.source-container .source-text {
  padding: 8px;
  display: flex;
  align-items: center;
}

.chip-text {
  word-break: break-all;
}

.source-container .source-close {
  padding: 12px 0;
}

.source-container img {
  margin: 8px;
  object-fit: contain;
}

.ripple-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px; /* Set the desired size */
  height: 36px; /* Set the desired size */
  border-radius: 50%; /* Make it round */
  overflow: hidden; /* Ensure the ripple effect does not overflow the round container */
  cursor: pointer; /* Adds a pointer cursor to indicate the image is interactive */
}

.empty-collection-prompt {
  color: grey;
  font-size: 11px;
  padding: 0 8px;
}

.asset-image {
  margin: 0 8px;
  border-radius: 8px !important;
  object-fit: fill !important;
  height: 260px !important;
}

.provider-group {
  margin: 0 0 -16px;
}

.provider-group .v-col {
  padding: 8px 16px;
}

.chip-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.chip-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.chip-item {
  flex: 0 0 auto;
  font-size: 12px !important;
}
</style>
