<script setup>
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {computed, ref, watch} from "vue";
import configs from "@/configs";
import axios from "axios";
import {parseTimeString} from "@/common/utils";
import ScheduleArticle from "@/components/ScheduleArticle.vue";
import Navigation from "@/components/Navigation.vue";
import BottomLine from "@/components/BottomLine.vue";
import * as adminCommon from "@/common/admin";
import ScheduleTrending from "@/components/ScheduleTrending.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const token = computed(() => store.getters.token);
console.log(`token: ${JSON.stringify(token.value)}`);
const isAdmin = computed(() => store.getters.isAdmin);

const props = defineProps(['id'])
console.log(`character id for schedule: ${props.id}`);

let schedules = ref([]);
let pageSize = 20;
let pageKey = null;
let noMoreElements = false;

const sortByTimeSlot = (scheduleA, scheduleB) => {
  // Create two new Date objects for each hour
  const dateA = new Date();
  const dateB = new Date();

  // Set the hour in UTC, reset minutes, seconds, and milliseconds to zero
  dateA.setUTCHours(scheduleA.time_slot, 0, 0, 0);
  dateB.setUTCHours(scheduleB.time_slot, 0, 0, 0);

  // Convert to local time
  const localTimeA = dateA.getHours(); // Get the hour in local time
  const localTimeB = dateB.getHours(); // Get the hour in local time

  // Compare the local times
  return localTimeA - localTimeB;
};

let composeTime = function(hour) {
  const now = new Date();

  // Set the hour and reset minutes, seconds, and milliseconds to zero
  now.setUTCHours(hour, 0, 0, 0);

  // Convert to local timezone
  const localTime = now.toLocaleTimeString(
      [], { hour: '2-digit', minute: '2-digit', hour12: true });

  const [timePart, amPmPart] = localTime.split(' ');

  return {
    time: timePart, // HH:MM part
    period: amPmPart // AM/PM part
  };
}

let article_types = [
    "K", "B", "M", "A", "P", "N"
]

let legendOfTags = new Map();
legendOfTags.set("K", "Knowledge");
legendOfTags.set("B", "Blog Post");
legendOfTags.set("M", "Moments");
legendOfTags.set("A", "Audio Blog");
legendOfTags.set("P", "Promotion");
legendOfTags.set("N", "News");

let typesOfSchedule = function(schedule) {
  if (schedule.type === "collect_news") {
    return ["N"];
  } else if (schedule.type === "write_article") {
    let types = schedule.args.article_types;
    if (!types || types.length === 0) {
      return [];
    }

    let mapOfTypes = [];
    for (let type of types) {
      switch (type) {
        case "knowledge":
          mapOfTypes.push("K");
          break;
        case "blogpost":
          mapOfTypes.push("B");
          break;
        case "moments":
          mapOfTypes.push("M");
          break;
        case "audioblog":
          mapOfTypes.push("A");
          break;
        case "apppromote":
          mapOfTypes.push("P");
          break;
      }
    }

    return mapOfTypes;
  } else {
    return [];
  }
}


let hasType = function(schedule, type) {
  let types = typesOfSchedule(schedule)


  for (let check_type of types) {
    if (check_type === type) {
      return true;
    }
  }

  return false;
}

let cancelSchedule = async function(schedule) {
  const sid = schedule.id;
  console.log(`cancel schedule: ${sid}`);
  if (!sid) {
    console.error(`sid cannot be empty.`)
    return;
  }

  console.log(`token: ${token.value}`)
  if (!isAdmin) {
    console.error(`no permit to run this in non-admin page.`)
    return;
  }

  let urlPrefix = configs.cloudFunctionPrefix;
  console.log(`urlPrefix: ${urlPrefix}`);

  let url = urlPrefix + `/v1/schedule/delete?id=${sid}`;
  console.log(`url: ${url}`);

  try {
    schedule.apiCalling = true;
    let ret = await axios.post(url, {},{
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });

    if (ret && ret.data && ret.data.code === 200) {
      console.log(`schedules BEFORE: ${JSON.stringify(schedules.value)}`);
      schedules.value = schedules.value.filter(element => element.id !== sid);
      console.log(`schedules AFTER: ${JSON.stringify(schedules.value)}`);
      await forceRender();
    }
  } catch (e) {
    console.error(`failed to call cancel api: ${e}`);
  } finally {
    schedule.apiCalling = false;
  }
}

let portraitOfSchedule = function (schedule) {
  if (!schedule || !schedule.author) {
    return null;
  }

  return schedule.author.portrait_thumb || schedule.author.portrait;
}

let reloadSchedules = async function() {
  schedules.value = [];
  noMoreElements = false;
  pageKey = null;
  // this.$emit('load', {side: 'end'})
  forceRender()
}

let listSchedules =  async function ({ done }) {
  console.log(`list schedules: no more = ${noMoreElements}`);

  if (noMoreElements) {
    done('empty')
    return
  }

  let urlPrefix = configs.cloudFunctionPrefix;
  console.log(`urlPrefix: ${urlPrefix}`);

  let url = urlPrefix + `/v1/schedule/list`;
  console.log(`url: ${url}`);

  url +=`?page_size=${pageSize}`
  if (pageKey) {
    url += `&page_key=${pageKey}`
  }

  try {
    let ret = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });

    if (ret && ret.data && ret.data.code === 200) {
      console.log(`ret: [${JSON.stringify(ret.data)}]`);
      let listOfSchedules = ret.data.schedules;

      for (let i = 0; i < listOfSchedules.length; i++){
        let item = listOfSchedules[i]
        schedules.value.push(item)
      }

      schedules.value = schedules.value.sort(sortByTimeSlot);

      pageKey = ret.data.nextPage
      if (ret.data.nextPage) {
        console.log('status: ok')
        done('ok')
      } else {
        console.log('status: empty')
        done('empty')
        noMoreElements = true
      }
    } else {
      done('error')
    }
  } catch (e) {
    console.error(`failed to call apply api: ${e}`);
    done('error')
  }
}

const viewCharacter = (characterId) => {
  let path = "/view_character"

  if (adminCommon.isAdministrationPage()) {
    path += "_admin";
  }

  router.push({
    path: path,
    query: { id: characterId }
  });
};


const infiniteKey = ref(0);

const forceRender = () => {
  infiniteKey.value += 1;
};

</script>

<template>
  <v-app class="oms-app">
    <Navigation/>
    <v-main class="schedule_frame" fluid>
      <v-row
          class="legend"
          v-show="legendOfTags != null">
        <v-col
            class="legend-item"
            v-for="k in legendOfTags.keys()"
            :key="k"
            cols="4"
            md="4"
        >
          <v-row
              justify="start"
              align="center"
          >
            <v-chip
                size="small"
            >
              {{ k }}
            </v-chip>
            <span class="legend-label"> {{ legendOfTags.get(k)}}</span>
          </v-row>

        </v-col>
      </v-row>
      <v-row
          style="margin: 0"
          justify="start"
          align="center"
      >
        <div class="schedules-title">
          <p> {{  $t("message.pageManageSchedulesTitle") }}</p>
        </div>

        <v-spacer/>
        <ScheduleTrending @done="reloadSchedules()"/>
      </v-row>
      <v-divider/>
      <v-infinite-scroll
          :items="schedules"
          :key="infiniteKey"
          @load="listSchedules">
        <template v-slot:empty>
          <BottomLine/>
        </template>
        <v-list-item
            class="schedule"
            v-for="(schedule, i) in schedules" :key="i"
        >
          <v-row
              align="center"
              justify="center"
          >
            <v-col
                cols="3"
                md="3"
                class="portrait"
                v-on:click="viewCharacter(schedule.author.id)">
              <v-avatar
                  v-show="portraitOfSchedule(schedule)"
                  color="#eee"
                  size="64"
                  :image="portraitOfSchedule(schedule)"
              >
              </v-avatar>
            </v-col>
            <v-col
                cols="9"
                md="9"
                class="meta"
            >
              <v-row
                  align="center"
                  justify="center"
              >
                <v-col
                    class="schedule-item"
                    cols="6"
                    md="6"
                >
                  <span class="schedule-time">{{ composeTime(schedule.time_slot).time }} </span>
                  <span>{{ composeTime(schedule.time_slot).period }}</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-row
                    align="center"
                    justify="center"
                    style="margin: 0 -10px 0 0"
                >
                  <v-btn
                      v-if="!schedule.apiCalling"
                      variant="plain"
                      v-on:click="cancelSchedule(schedule)"
                  >
                    <v-img width="32" src="@/assets/4115238_delete_trash_icon.svg" />
                  </v-btn>
                  <v-progress-circular
                      v-else
                      :size="25"
                      :width="3"
                      indeterminate
                      style="margin-top: -4px"
                  ></v-progress-circular>

                </v-row>
              </v-row>
              <v-row
                  class="article-types"
                  align="center"
                  justify="center">
                <v-col
                    cols="2"
                    md="2"
                    class="article-type"
                    v-for="(type, i) in article_types"
                >
                  <v-chip
                      size="small"
                      :variant="hasType(schedule, type) ? 'flat' : 'tonal'"
                  >
                    {{type}}
                  </v-chip>
                </v-col>
                <v-spacer/>
              </v-row>
            </v-col>
          </v-row>
        </v-list-item>
      </v-infinite-scroll>
    </v-main>
  </v-app>
</template>

<style scoped>
.schedule_frame {
  padding: 16px;
}
.schedule {
  margin: 8px 0;
  padding: 0;
}

.schedule .v-row {
  margin: 0;
}

.schedule .v-col {
  padding: 0;
}

.schedule-time {
  font-size: 36px;
  font-weight: bold;
  padding-right: 4px;
}

.schedule-item {
  padding: 0px 8px;
}

.article-types {
  padding: 0 8px;
}

.article-type {
  padding: 0;

}
.article-type .v-chip {

}

.portrait {
  padding: 0;
}

.portrait :deep(img) {
  border-radius: 8px;
}

.meta {
  padding: 0;
}

.legend {
  margin: 0;
  font-size: 14px;
}

.legend .v-row {
  margin: 0;
}

.legend-item {
  padding: 4px;
}

.legend-label {
  padding-left: 4px ;

}

.schedules-title {
  font-size: 20px !important;
  font-weight: bold;
  padding: 16px 0 8px;
}

</style>
