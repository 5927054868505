<script setup>
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {computed, ref, watch} from "vue";
import configs from "@/configs";
import axios from "axios";
import {parseTimeString} from "@/common/utils";

const store = useStore();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const token = computed(() => store.getters.token);
console.log(`token: ${JSON.stringify(token.value)}`);
const isAdmin = computed(() => store.getters.isAdmin);

const props = defineProps(['id'])
console.log(`character id for schedule: ${props.id}`);

const emit = defineEmits(['done'])

const showDialog = ref(false  )
const timeSlots = [
  "12:00",
  "1:00", "2:00", "3:00", "4:00", "5:00", "6:00",
  "7:00", "8:00", "9:00", "10:00", "11:00",
]
let scheduleTime = ref("12:00 AM");
let selectedTime = ref("12:00");
let selectedPeriod = ref("AM");

watch(selectedTime, (time) => {
  scheduleTime.value = `${time} ${selectedPeriod.value}`
  console.log(`schedule on : ${scheduleTime.value}`);
});

watch(selectedPeriod, (period) => {
  scheduleTime.value = `${selectedTime.value} ${period}`
  console.log(`schedule on : ${scheduleTime.value}`);
});


let cnIPS = ref(null);
let cnIPSEnabled = ref(false);
const cnIPSMin = 1;
const cnIPSMax = 10;
watch(cnIPSEnabled, (enabled) => {
  if (enabled) {
    cnIPS.value = Math.round((cnIPSMax - cnIPSMin) / 2) + cnIPSMin;
  } else {
    cnIPS.value = null;
  }
});

const cnIPSThumb = computed(() => {
  if (cnIPSEnabled.value) {
    return "always"
  } else {
    return false;
  }
});

const siteThePager = {
  value: "https://thepaper.cn",
  title: t('message.pageTrendingSourceTitleThePaper')
};
const siteToutiao = {
  value: "https://www.toutiao.com",
  title: t('message.pageTrendingSourceTitleToutiao')
};
const siteChinaNews = {
  value: "https://www.chinanews.com/scroll-news/news1.html",
  title: t('message.pageTrendingSourceTitleChinaNews')
};
const siteRedditAfrica = {
  value: "https://www.reddit.com/r/Africa/top/?t=day",
  title: t('message.pageTrendingSourceTitleRedditAfrica')
};
const siteBBCSport = {
  value: "https://www.bbc.com/sport/olympics",
  title: t('message.pageTrendingSourceTitleBBCSport')
};
const siteBBC = {
  value: "https://www.bbc.com/",
  title: t('message.pageTrendingSourceTitleBBC')
};
const siteCNN = {
  value: "https://edition.cnn.com/",
  title: t('message.pageTrendingSourceTitleCNN')
};
const siteGooglePlayApps = {
  value: "https://play.google.com/store/apps",
  title: t('message.pageTrendingSourceTitleGooglePlayApps')
};
const siteGooglePlayGames = {
  value: "https://play.google.com/store/games",
  title: t('message.pageTrendingSourceTitleGooglePlayGames')
};
const pre_defined_sites = [
  siteThePager, siteToutiao, siteChinaNews,
  siteRedditAfrica, siteBBCSport, siteBBC, siteCNN,
  siteGooglePlayApps, siteGooglePlayGames
]
const defaultSites = [
]
let cnSites = ref(null);
let cnSitesEnabled = ref(false);
let newSite = ref(null)
watch(cnSitesEnabled, (enabled) => {
  if (enabled) {
    cnSites.value = [...defaultSites];
  } else {
    cnSites.value = null;
    newSite.value = null;
  }
});

let siteIcon = function (item) {
  if (cnSites.value && cnSites.value.some(selected => selected.value === item.value)) {
    return 'custom:checkIcon';
  }

  return 'custom:uncheckIcon';
}

let toggleSite = function (item) {
  console.log(`toggle item: ${JSON.stringify(item)}`)
  console.log(`cnSits: ${JSON.stringify(cnSites.value)}`)
  if (!cnSites.value) {
    cnSites.value = [];
  }

  const index = cnSites.value.findIndex(
      selected => selected.value === item.value
  );

  console.log(`item index: ${index}`);

  if (index === -1) {
    cnSites.value.push(item);
  } else {
    cnSites.value.splice(index, 1);
  }
}

let apiCalling = ref(false);

let scheduleTrending = async function() {
  console.log(`token: ${token.value}`)
  if (!isAdmin) {
    console.error(`no permit to run this in non-admin page.`)
    return;
  }

  let urlPrefix = configs.cloudFunctionPrefix;
  console.log(`urlPrefix: ${urlPrefix}`);

  let url = urlPrefix + `/v1/schedule/create`;
  console.log(`url: ${url}`);

  let time = parseTimeString(scheduleTime.value)
  console.log(`time: ${JSON.stringify(time)}`);

  let data = {
    cid: -1,
    type: "collect_news",
    time: time,
  };

  let args = {};

  let numOfItems = 0;
  const sites = cnSites.value;
  if (sites && sites.length > 0) {
    args.news_sites = sites.map(site => site.value);
    numOfItems += sites.length;
  }

  if (numOfItems === 0) {
    console.error(`no items to collect.`)

    return;
  }

  if (cnIPS.value) {
    args.nps = cnIPS.value;
  }

  data.args = JSON.stringify(args);
  console.log(`data: ${JSON.stringify(data)}`);

  try {
    apiCalling.value = true;
    let ret = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });

    if (ret && ret.data && ret.data.code === 200) {
      console.log(`composition of articles is scheduled.`);
    }
  } catch (e) {
    console.error(`failed to call schedule article api: ${e}`);
  } finally {
    apiCalling.value = false;
    emit('done')
  }
}

</script>

<template>
  <v-dialog scrollable
      v-model="showDialog"
      max-width="340">
    <template v-slot:default="{ isActive }">
      <v-card
          :title='$t("message.dialogScheduleTrendingTitle")'
      >
        <template v-slot:prepend>
          <img
              width="32"
              style="margin: 0 10px"
              src="@/assets/115708_news_newspaper_subscribe_icon.svg" />
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn
              class="ml-auto"
              :text='$t("message.commonActionSchedule")'
              @click="showDialog = false; scheduleTrending()"
          ></v-btn>
          <v-btn
              class="ml-auto"
              :text='$t("message.commonActionCancel")'
              @click="showDialog = false"
          ></v-btn>
        </template>
        <v-col class="customization">
          <v-row
              class="option-title"
              align-content="center"
          >
            <div class="option-label"> {{ $t('message.dialogScheduleLabelTime') }}</div>
          </v-row>
          <v-select
              :label="$t('message.dialogScheduleHintTime')"
              :items="timeSlots"
              v-model="selectedTime"
          />
          <v-radio-group
              inline
              v-model="selectedPeriod"
          >
            <v-radio
                true-icon="custom:radioCheckIcon"
                false-icon="custom:radioUncheckIcon"
                :label="$t('message.dialogSchedulePeriodAM')"
                value="AM">
            </v-radio>
            <v-radio
                true-icon="custom:radioCheckIcon"
                false-icon="custom:radioUncheckIcon"
                :label="$t('message.dialogSchedulePeriodPM')"
                value="PM">
            </v-radio>
          </v-radio-group>
          <v-checkbox
              class="cn-option-title"
              true-icon="custom:checkIcon"
              false-icon="custom:uncheckIcon"
              :label="$t('message.pageTrendingIPSLabel')"
              v-model="cnIPSEnabled">
          </v-checkbox>
          <v-slider
              v-model="cnIPS"
              :disabled="!cnIPSEnabled"
              :min="cnIPSMin"
              :max="cnIPSMax"
              step="1"
              :thumb-label="cnIPSThumb"
          ></v-slider>
          <v-checkbox
              class="cn-option-title"
              true-icon="custom:checkIcon"
              false-icon="custom:uncheckIcon"
              :label="$t('message.pageTrendingLabelSites')"
              v-model="cnSitesEnabled">
          </v-checkbox>
          <v-combobox
              v-model="cnSites"
              :disabled="!cnSitesEnabled"
              :items="pre_defined_sites"
              chips
              multiple
          >
            <template v-slot:selection="data">
              <v-chip
                  @click:close="removeItem(data.item)"
              >
                {{ data.item.title }}
              </v-chip>
            </template>
            <template v-slot:item=" { item }">
              <v-list-item
                  :title="item.title"
                  :subtitle="item.value"
                  @click="toggleSite(item)"
              >
                <template v-slot:prepend>
                  <v-icon :icon="siteIcon(item)"/>
                </template>
              </v-list-item>
            </template>
          </v-combobox>
        </v-col>
      </v-card>
    </template>
  </v-dialog>
  <div v-show="isAdmin">
    <v-btn
        v-if="!apiCalling"
        variant="plain"
        class="gen-article-btn"
        @click="showDialog = true"
    >
      <v-img
          width="36px"
          height="36px"
          src="@/assets/115708_news_newspaper_subscribe_icon.svg" />
    </v-btn>
    <v-progress-circular
        v-else
        :size="25"
        :width="3"
        indeterminate
        style="margin-top: -4px"
    ></v-progress-circular>
  </div>
</template>

<style scoped>

</style>
