<script setup>
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {computed, ref, watch} from "vue";
import configs from "@/configs";
import axios from "axios";
import {parseTimeString} from "@/common/utils";

const store = useStore();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const token = computed(() => store.getters.token);
console.log(`token: ${JSON.stringify(token.value)}`);
const isAdmin = computed(() => store.getters.isAdmin);

const props = defineProps(['id'])
console.log(`character id for schedule: ${props.id}`);

const emit = defineEmits(['done'])

const showDialog = ref(false  )
const timeSlots = [
  "12:00",
  "1:00", "2:00", "3:00", "4:00", "5:00", "6:00",
  "7:00", "8:00", "9:00", "10:00", "11:00",
]
let scheduleTime = ref("12:00 AM");
let selectedTime = ref("12:00");
let selectedPeriod = ref("AM");

watch(selectedTime, (time) => {
  scheduleTime.value = `${time} ${selectedPeriod.value}`
  console.log(`schedule on : ${scheduleTime.value}`);
});

watch(selectedPeriod, (period) => {
  scheduleTime.value = `${selectedTime.value} ${period}`
  console.log(`schedule on : ${scheduleTime.value}`);
});

let apiCalling = ref(false);

let scheduleArticle = async function() {
  const characterId = props.id
  console.log(`characterId: ${characterId}`)
  if (!characterId) {
    console.error(`character ID is not set yet.`)
    return;
  }

  console.log(`token: ${token.value}`)
  if (!isAdmin) {
    console.error(`no permit to run this in non-admin page.`)
    return;
  }

  let urlPrefix = configs.cloudFunctionPrefix;
  console.log(`urlPrefix: ${urlPrefix}`);

  let url = urlPrefix + `/v1/schedule/create`;
  console.log(`url: ${url}`);

  let time = parseTimeString(scheduleTime.value)
  console.log(`time: ${JSON.stringify(time)}`);

  let data = {
    cid: characterId,
    type: "write_article",
    time: time,
  };

  let args = {};
  let selectedTypes = types.value;
  if (selectedTypes && selectedTypes.length > 0) {
    args.article_types = selectedTypes.map(site => site.value);
  }

  if (lang.value) {
    args.lang = lang.value;
  }

  if (source.value) {
    args.sid = source.value;
  }

  data.args = JSON.stringify(args);
  console.log(`data: ${JSON.stringify(data)}`);

  try {
    apiCalling.value = true;
    let ret = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });

    if (ret && ret.data && ret.data.code === 200) {
      console.log(`composition of articles is scheduled.`);
    }
  } catch (e) {
    console.error(`failed to call schedule article api: ${e}`);
  } finally {
    apiCalling.value = false;
    emit('done')
  }
}

const languages = [
  {
    label: t("message.commonOptionLangEn"),
    value: "English",
  },
  {
    label: t("message.commonOptionLangFr"),
    value: "French",
  },
  {
    label: t("message.commonOptionLangSp"),
    value: "Spanish",
  },
  {
    label: t("message.commonOptionLangZh"),
    value: "Chinese",
  },
];

const lang = ref(sessionStorage.getItem('acLanguage'));
const langEnabled = ref(lang.value != null);
console.log(`lang: ${JSON.stringify(lang.value)}`);
console.log(`langEnabled: ${JSON.stringify(langEnabled.value)}`);
watch(langEnabled, (enabled) => {
  if (enabled) {
    lang.value = "English";
  } else {
    lang.value = null;
  }
});

const articleTypes = [
  {
    label: t("message.commonOptionArticleTypeKnowledge"),
    value: "knowledge",
  },
  {
    label: t("message.commonOptionArticleTypeBlogpost"),
    value: "blogpost",
  },
  {
    label: t("message.commonOptionArticleTypeMoments"),
    value: "moments",
  },
  {
    label: t("message.commonOptionArticleTypeAlog"),
    value: "audioblog",
  },
  {
    label: t("message.commonOptionArticleTypeAppPromote"),
    value: "apppromote",
  }
];
let types = ref(null);
const typeEnabled = ref(types.value != null);

watch(typeEnabled, (enabled) => {
  if (enabled) {
    types.value = [ articleTypes[1] ];
  } else {
    types.value = null;
  }
});

let typeIcon = function (item) {
  if (types.value && types.value.some(selected => selected.value === item.value)) {
    return 'custom:checkIcon';
  }

  return 'custom:uncheckIcon';
}

let toggleType = function (item) {
  console.log(`toggle item: ${JSON.stringify(item)}`)
  console.log(`types: ${JSON.stringify(types.value)}`)
  if (!types.value) {
    types.value = [];
  }

  const index = types.value.findIndex(
      selected => selected.value === item.value
  );

  console.log(`item index: ${index}`);

  if (index === -1) {
    types.value.push(item);
  } else {
    types.value.splice(index, 1);
  }
}

const source = ref(null)
const sourceEnabled = ref(source.value != null);
watch(sourceEnabled, (enabled) => {
  if (enabled) {
    source.value = "random";
  } else {
    source.value = null;

  }
});

</script>

<template>
  <v-dialog
      v-model="showDialog"
      max-width="340">
    <template v-slot:default="{ isActive }">
      <v-card
          :title='$t("message.dialogScheduleArticleTitle")'
      >
        <template v-slot:prepend>
          <img
              width="32"
              style="margin: 0 10px"
              src="@/assets/392529_alarm_alert_clock_event_history_icon.svg" />
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn
              class="ml-auto"
              :text='$t("message.commonActionSchedule")'
              @click="showDialog = false; scheduleArticle()"
          ></v-btn>
          <v-btn
              class="ml-auto"
              :text='$t("message.commonActionCancel")'
              @click="showDialog = false"
          ></v-btn>
        </template>
        <v-col class="customization">
          <v-row
              class="option-title"
              align-content="center"
          >
            <div class="option-label"> {{ $t('message.dialogScheduleLabelTime') }}</div>
          </v-row>
          <v-select
              :label="$t('message.dialogScheduleHintTime')"
              :items="timeSlots"
              v-model="selectedTime"
          />
          <v-radio-group
              inline
              v-model="selectedPeriod"
          >
            <v-radio
                true-icon="custom:radioCheckIcon"
                false-icon="custom:radioUncheckIcon"
                :label="$t('message.dialogSchedulePeriodAM')"
                value="AM">
            </v-radio>
            <v-radio
                true-icon="custom:radioCheckIcon"
                false-icon="custom:radioUncheckIcon"
                :label="$t('message.dialogSchedulePeriodPM')"
                value="PM">
            </v-radio>
          </v-radio-group>
          <v-row
              class="option-title"
              align-content="center"
          >
            <v-checkbox
                true-icon="custom:checkIcon"
                false-icon="custom:uncheckIcon"
                :label="$t('message.commonOptionLabelLanguage')"
                v-model="langEnabled">
            </v-checkbox>
          </v-row>
          <v-select
              :disabled="!langEnabled"
              :label="$t('message.commonOptionLabelLanguage')"
              :items="languages"
              item-title="label"
              item-value="value"
              v-model="lang"
          >
          </v-select>
          <v-row
              class="option-title"
              align-content="center"
          >
            <v-checkbox
                true-icon="custom:checkIcon"
                false-icon="custom:uncheckIcon"
                :label="$t('message.commonOptionLabelArticleType')"
                v-model="typeEnabled">
            </v-checkbox>
          </v-row>
          <v-select
              :disabled="!typeEnabled"
              :label="$t('message.commonOptionLabelArticleType')"
              :items="articleTypes"
              v-model="types"
              item-title="label"
              chips
              multiple
          >
            <template v-slot:item=" { item }">
              <v-list-item
                  :title="item.raw.label"
                  @click="toggleType(item)"
              >
                <template v-slot:prepend>
                  <v-icon :icon="typeIcon(item)"/>
                </template>
              </v-list-item>
            </template>
          </v-select>
          <v-row
              class="option-title"
              align-content="center"
          >
            <v-checkbox
                true-icon="custom:checkIcon"
                false-icon="custom:uncheckIcon"
                :label="$t('message.dialogScheduleInspiredBySource')"
                v-model="sourceEnabled">
            </v-checkbox>
          </v-row>
        </v-col>
      </v-card>
    </template>
  </v-dialog>
  <v-row
      v-show="isAdmin"
      style="margin: 16px"
      justify="center"
      align="center"
  >
    <v-btn
        variant="tonal"
        :disabled="apiCalling"
        v-on:click="showDialog = true"
    >
      <v-progress-circular
          v-if="apiCalling"
          :size="20"
          :width="1"
          style="margin-right: 8px"
          indeterminate
      ></v-progress-circular>

      {{ $t('message.dialogScheduleActionAdd') }}
    </v-btn>

  </v-row>
</template>

<style scoped>

</style>
