<template>
  <v-app class="oms-app">
    <v-main class="d-flex justify-center align-center">
      <div>
        <p v-if="user">{{ $t("message.pageSignedInPrompt") }}{{ user.displayName }}</p>
        <p v-else>{{ $t("message.pageSigningInPrompt") }}</p>
      </div>
    </v-main>
    <v-footer v-show="showFooter" fixed app>
      <v-container>
        <v-col id="copyright"
               class="text-center"
               cols="12"
        >
          {{ $t("message.commonFooterCopyright") }} {{ new Date().getFullYear() }} &#169;
        </v-col>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import { useCurrentUser } from 'vuefire';
import { useRouter, useRoute } from 'vue-router';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import {useI18n} from "vue-i18n";
import configs from "@/configs";
import axios from "axios";

export default {
  name: 'Login',
  data() {
    return {
      fullPath: this.$route.fullPath,
      query: this.$route.query,
      theme: 'light',
      locale: 'en',
      docHeight: document.documentElement.clientHeight,
      displayHeight: document.documentElement.clientHeight,
      showFooter: true,
    }
  },
  setup() {
    const store = useStore();
    const user = useCurrentUser();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();

    const handleSignIn = async () => {
      await store.dispatch('handleSignInResult');
      console.log(`user in Login: ${JSON.stringify(user)}`);
      console.log(`store.getters.user in Login: ${JSON.stringify(store.getters.user)}`);

      if (store.getters.user) {
        const redirectTo = route.query.redirect || '/';
        router.push(redirectTo);
      } else {
        await store.dispatch('signIn');
      }
    };

    onMounted(async () => {
      await handleSignIn();
    });

    return {user};
  },
};
</script>

<style scoped>
p {
  height: 100%;
  text-align: center;
  text-justify: auto;
  font-size: 16px;
}
</style>
