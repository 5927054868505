const CONFIG_DIRECTORY = "config";

let configs = {};

console.log(`building environment: ${process.env.NODE_ENV}`)
if (process.env.NODE_ENV === 'development') {
    configs = await import(`./${CONFIG_DIRECTORY}/config_dev.js`);
} else if (process.env.NODE_ENV === 'production') {
    configs = await import(`./${CONFIG_DIRECTORY}/config_prod.js`);
} else if (process.env.NODE_ENV === 'development-pro') {
    configs = await import(`./${CONFIG_DIRECTORY}/config_dev_pro.js`);
} else if (process.env.NODE_ENV === 'production-pro') {
    configs = await import(`./${CONFIG_DIRECTORY}/config_prod_pro.js`);
}

console.log(`mode: [${process.env.NODE_ENV}], app configs: ${JSON.stringify(configs)}`)

export default configs.default
