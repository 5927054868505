import * as utils from './utils';

const adminSuppressedPages = [
    "articles",
    "view_article"
]

export function isAdminAllowed() {
    const lastSegment = utils.getLastPathSegment(window.location.href);

    return lastSegment && !adminSuppressedPages.includes(lastSegment);
}

export function isAdministrationPage() {
    const lastSegment = utils.getLastPathSegment(window.location.href);

    return lastSegment && lastSegment.endsWith("_admin");
}


export function adjustAdminPath(path, admin) {
    if (admin) {
        return path.includes('_admin') ? path : `${path}_admin`;
    } else {
        return path.includes('_admin') ? path.replace('_admin', '') : path;
    }
}
