// src/store/index.js
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { getAuth, GoogleAuthProvider, signInWithRedirect, getRedirectResult, onAuthStateChanged } from 'firebase/auth';
import configs from "@/configs";
import axios from "axios";

async function isAdministrator(token) {
    console.log(`token: ${token}`)

    let urlPrefix = configs.cloudFunctionPrefix;
    console.log(`urlPrefix: ${urlPrefix}`);

    let url = urlPrefix + `/v1/user/admin`;
    console.log(`url: ${url}`);

    let isAdmin = false;
    try {
        let ret = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (ret && ret.data && ret.data.code === 200) {
            isAdmin = ret.data.isAdministrator;
            console.log(`is administrator: ${isAdmin}`);
        }
    } catch (e) {
        console.error(`failed to call create character api: ${e}`);
        isAdmin = false
    }

    return isAdmin;
}

const store = createStore({
    state: {
        user: null,
        token: null,
        isAdmin: false,
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setToken(state, token) {
            state.token = token;
        },
        setAdmin(state, isAdmin) {
            state.isAdmin = isAdmin;
        },
        clearAuthState(state) {
            state.user = null;
            state.token = null;
            state.isAdmin = false;
        },
    },
    actions: {
        async signIn({ commit }) {
            console.log(`sign in with Google`)
            const auth = getAuth();
            const provider = new GoogleAuthProvider();
            await signInWithRedirect(auth, provider);
        },

        async handleSignInResult({ commit }) {
            const auth = getAuth();
            const result = await getRedirectResult(auth);
            console.log(`result of getRedirectResult(): ${JSON.stringify(result)}`);

            if (result && result.user) {
                const idToken = await result.user.getIdToken();
                const isAdmin = await isAdministrator(idToken);

                commit('setUser', result.user);
                commit('setToken', idToken);
                commit('setAdmin', isAdmin);
            }
        },
        async signOut({ commit }) {
            const auth = getAuth();
            await auth.signOut();
            commit('clearAuthState');
        },
        async fetchUser({ commit }) {
            const auth = getAuth();
            return new Promise((resolve, reject) => {
                const unsubscribe = onAuthStateChanged(auth, async (user) => {
                    unsubscribe();
                    if (user) {
                        const token = await user.getIdToken();
                        commit('setUser', user);
                        commit('setToken', token);
                        resolve(user);
                    } else {
                        resolve(null);
                    }
                }, reject);
            });
        },
        async refreshToken({ commit }) {
            console.log('refreshing token');
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                const idToken = await user.getIdToken(true);
                commit('setToken', idToken);
            }
        },
        startTokenRefreshTimer({ dispatch }) {
            // Refresh the token 5 minutes before it expires
            setInterval(async () => {
                await dispatch('refreshToken');
            }, 30 * 60 * 1000); // 55 minutes in milliseconds
        },
    },
    getters: {
        user: (state) => state.user,
        token: (state) => state.token,
        isAdmin: (state) => state.isAdmin,
    },
    plugins: [createPersistedState()],
});

export default store;
