<script setup>
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {computed, ref, watch} from "vue";
import configs from "@/configs";
import axios from "axios";

const store = useStore();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const token = computed(() => store.getters.token);
console.log(`token: ${JSON.stringify(token.value)}`);
const isAdmin = computed(() => store.getters.isAdmin);

const props = defineProps(['id'])
console.log(`article id for rewrite: ${props.id}`);

const showDialog = ref(false)

const stepArticle = {
  value: "article",
  title: t('message.pageViewArticleStepArticleDesc')
};
const stepReview = {
  value: "review",
  title: t('message.pageViewArticleStepReviewDesc')
};
const stepAsset = {
  value: "asset",
  title: t('message.pageViewArticleStepAssetDesc')
};
const availableSteps = [
  stepArticle,
  stepReview,
  stepAsset,
]

console.log(`available steps:${JSON.stringify(availableSteps)}`)
const defaultSteps = availableSteps;

let redoSteps = ref(null);
let redoStepsEnabled = ref(false);

watch(redoStepsEnabled, (enabled) => {
  if (enabled) {
    redoSteps.value = [...defaultSteps];
  } else {
    redoSteps.value = null;
  }
});

let stepIcon = function (item) {
  if (redoSteps.value && redoSteps.value.some(selected => selected.value === item.value)) {
    return 'custom:checkIcon';
  }

  return 'custom:uncheckIcon';
}

let toggleStep = function (item) {
  console.log(`toggle item: ${JSON.stringify(item)}`)
  console.log(`redoSteps: ${JSON.stringify(redoSteps.value)}`)
  if (!redoSteps.value) {
    redoSteps.value = [];
  }

  const index = redoSteps.value.findIndex(
      selected => selected.value === item.value
  );

  console.log(`item index: ${index}`);

  if (index === -1) {
    redoSteps.value.push(item);
  } else {
    redoSteps.value.splice(index, 1);
  }
}

let rewriteExecution = ref(null);
let rewriteCheckHandler = null;
let executionCheckInterval = 10000;


let rewriteArticle = async function() {
  const articleId = props.id
  console.log(`articleId: ${articleId}`)
  if (!articleId) {
    console.error(`article ID is not set yet.`)
    return;
  }

  console.log(`token: ${token.value}`)
  if (!isAdmin) {
    console.error(`no permit to run this in non-admin page.`)
    return;
  }

  let urlPrefix = configs.cloudFunctionPrefix;
  console.log(`urlPrefix: ${urlPrefix}`);

  let url = urlPrefix + `/v1/article/redo`;
  console.log(`url: ${url}`);

  let data = {
    id: articleId
  };

  let numOfItems = 0;
  const steps = redoSteps.value;
  if (steps && steps.length > 0) {
    data.steps = steps.map(site => site.value).join(', ');
    numOfItems += data.steps.length;
  } else {
    data.steps = defaultSteps.map(site => site.value).join(', ');
    numOfItems += data.steps.length;
  }

  if (numOfItems === 0) {
    console.error(`no steps to do.`)

    return;
  }

  rewriteExecution.value = "";

  console.log(`data: ${JSON.stringify(data)}`);

  try {
    let ret = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });

    if (ret && ret.data && ret.data.code === 200) {
      console.log(`rewrite of article is requested.`);

      rewriteExecution.value = ret.data.execution;
      rewriteCheckHandler = setInterval(
          checkExecution,
          executionCheckInterval,
          rewriteExecution.value,
          rewriteDone);
    } else {
      cleanRewrite();
    }
  } catch (e) {
    console.error(`failed to call rewrite article api: ${e}`);

    cleanRewrite();
  }
}

const emit = defineEmits(['done'])
let rewriteDone = function (status) {
  console.log(`rewrite article status: ${status}`);

  if (status === 'succeed') {
    emit('done')
  }

  cleanRewrite();
}

let cleanRewrite = function () {
  if (rewriteCheckHandler) {
    clearInterval(rewriteCheckHandler)
    rewriteCheckHandler = null;
  }

  rewriteExecution.value = null;
}

let checkExecution = async function (executionId, done) {
  console.log(`checking status of execution [${executionId}].`);

  let urlPrefix = configs.cloudFunctionPrefix;
  console.log(`urlPrefix: ${urlPrefix}`);

  let url = urlPrefix + `/v1/execution/status?id=${executionId}`;
  console.log(`url: ${url}`);

  try {
    let ret = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });

    if (ret && ret.data && ret.data.code === 200) {
      console.log(`[${JSON.stringify(ret.data)}]`);
      if (ret.data.status === 'succeed' || ret.data.status === 'failed') {
        done(ret.data.status);
      }
    } else {
      console.error(`failed to execute api: ${ret.data.code}`)
    }
  } catch (e) {
    console.error(`failed to call get execution status api: ${e}`);
  }
}

</script>

<template>
  <v-dialog
      v-model="showDialog"
      max-width="340">
    <template v-slot:default="{ isActive }">
      <v-card
          :title='$t("message.pageViewArticleRewriteTitle")'
      >
        <template v-slot:prepend>
          <img
              width="32"
              style="margin: 0 10px"
              src="@/assets/8666806_edit_write_pen_icon.svg" />
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn
              class="ml-auto"
              :text='$t("message.pageViewArticleActionRewrite")'
              @click="showDialog = false; rewriteArticle()"
          ></v-btn>
          <v-btn
              class="ml-auto"
              :text='$t("message.commonActionCancel")'
              @click="showDialog = false"
          ></v-btn>
        </template>
        <div class="customization">
          <v-row
              class="option-title"
              align-content="center"
          >
            <v-checkbox
                true-icon="custom:checkIcon"
                false-icon="custom:uncheckIcon"
                :label="$t('message.pageViewArticleStepsLabel')"
                v-model="redoStepsEnabled">
            </v-checkbox>
          </v-row>
          <v-select
              :disabled="!redoStepsEnabled"
              :label="$t('message.pageViewArticleStepsHint')"
              :items="availableSteps"
              v-model="redoSteps"
              item-title="value"
              chips
              multiple
          >
            <template v-slot:item=" { item }">
              <v-list-item
                  :title="item.value"
                  :subtitle="item.raw.title"
                  @click="toggleStep(item)"
              >
                <template v-slot:prepend>
                  <v-icon :icon="stepIcon(item)"/>
                </template>
              </v-list-item>
            </template>
          </v-select>
        </div>
      </v-card>
    </template>
  </v-dialog>
  <div v-show="isAdmin">
    <v-btn
        v-if="rewriteExecution == null"
        variant="plain"
        class="gen-article-btn"
        @click="showDialog = true"
    >
      <img src="@/assets/8666806_edit_write_pen_icon.svg" />
    </v-btn>
    <v-progress-circular
        v-else
        :size="25"
        :width="3"
        indeterminate
        style="margin-top: -4px"
    ></v-progress-circular>
  </div>
</template>

<style scoped>

</style>
