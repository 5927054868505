<template>
  <v-app class="oms-app">
    <Navigation/>
    <v-main class="news_frame" fluid>
      <v-row class="tags-group"
          v-show="this.tags != null">
        <v-col cols="12" justify="left" align="left">
          <div class="chip-container">
            <v-chip
                v-for="chip in this.tags"
                :key="chip"
                class="ma-2 chip-item"
                :variant="this.chipVariant(chip)"
                @click="this.listArticlesByTag(chip.tag)"
            >
              {{ chip.label }}
            </v-chip>
          </div>
        </v-col>
      </v-row>
      <v-infinite-scroll
          :items="articles"
          :key="infiniteKey"
          @load="listArticles">
        <template v-slot:empty>
          <BottomLine/>
        </template>
        <v-list-item
            v-for="(article, i) in articles" :key="i"
            v-show="article.title !== null"
        >
          <v-card
              class="article-moments"
              v-if="article.type === 'moments'"
          >
            <v-row
                class="article-moments-images"
            >
              <v-col
                  class="article-moments-image"
                  v-for="(image, i) in articleImages(article)" :key="i"
                  v-show="image !== null"
                  :cols="articleImagesCols(article)"
                  :md="articleImagesCols(article)"
              >
                <div class="shrink-image-container">
                  <img
                      class="shrink-on-hover"
                      :src="image" />
                </div>
              </v-col>
            </v-row>
            <div
                class="article-moments-abstract"
                v-on:click="viewArticle(article.id)">
              {{ article.abstract }}
            </div>
            <v-card-subtitle>
              <v-row justify="start" align="center">
                <v-col
                    class="portrait"
                    v-on:click="viewCharacter(article.author.id)">
                  <v-avatar
                      color="#eee"
                      size="28"
                      :image="article.author.portrait"
                  >
                  </v-avatar>

                </v-col>
                <v-col
                    class="providers"
                    v-on:click="viewCharacter(article.author.id)">
                  <a>{{ article.author.display_name || article.author.name }}</a>
                  · {{ getElapseTime(article.created) }}
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-divider></v-divider>
          </v-card>
          <v-card
              v-else
              class="mx-auto"
              max-width="344"
          >
            <div class="asset-image shrink-image-container">
              <v-img class="shrink-on-hover"
                     :src="imageAsset(article)"
                     v-show="imageAsset(article) !== null"
                     cover
              >
              </v-img>
            </div>
            <audio
                class="audio-player"
                ref="audioPlayer"
                v-show="articleAudioAsset(article) !== null"
                controls>
              <source :src="articleAudioAsset(article)" type="audio/mpeg">
            </audio>

            <v-card-title v-on:click="viewArticle(article.id)">
              {{article.title}}
            </v-card-title>
            <v-card-subtitle>
              <v-row justify="start" align="center">
                <v-col
                    class="portrait"
                    v-on:click="viewCharacter(article.author.id)">
                <v-avatar
                      color="#eee"
                      size="28"
                      :image="article.author.portrait"
                  >
                  </v-avatar>

                </v-col>
                <v-col
                    class="providers"
                    v-on:click="viewCharacter(article.author.id)">
                  <a>{{ article.author.display_name || article.author.name }}</a>
                  · {{ getElapseTime(article.created) }}
                </v-col>

                <v-btn variant="plain" v-on:click="toggleArticle(i)">
                  more
                </v-btn>
              </v-row>
            </v-card-subtitle>

            <v-expand-transition>
              <div v-show="article.expand">
                <v-card-text>
                  {{article.abstract}}
                </v-card-text>
              </div>
            </v-expand-transition>
            <v-divider></v-divider>
          </v-card>
        </v-list-item>
      </v-infinite-scroll>
    </v-main>
  </v-app>
</template>

<script>
import configs from "../configs";
import {DateTime} from "luxon";
import axios from 'axios'
import {useRouter} from "vue-router";
import * as adminCommon from "@/common/admin";
import { useI18n } from 'vue-i18n';
import {ref} from "vue";
import Navigation from "@/components/Navigation.vue";
import MarkdownIt from "markdown-it";
import MarkdownItFootnote from "markdown-it-footnote";
import BottomLine from "@/components/BottomLine.vue";

const markdown = new MarkdownIt(
    {
      breaks: true
    }).use(MarkdownItFootnote)

export default {
  name: 'Articles',
  components: {BottomLine, Navigation},
  metaInfo() {
    return {
      title: this.$t('message.pageNewsAppName'),
    }
  },
  data() {
    return {
      fullPath: this.$route.fullPath,
      query: this.$route.query,
      theme: 'light',
      locale: 'en',
      docHeight: document.documentElement.clientHeight,
      displayHeight: document.documentElement.clientHeight,
      showFooter: true,
      errorMessage: null,
      style: '0',

      tags: [
        { tag: null, label: this.$t('message.pageArticlesTagAll') },
        { tag: 'Apps', label: this.$t('message.pageArticlesTagApps') },
        { tag: 'Articles', label: this.$t('message.pageArticlesTagArticles') },
        { tag: 'Blogs', label: this.$t('message.pageArticlesTagBlogs') },
        { tag: 'Chinese', label: this.$t('message.pageArticlesTagChinese') },
        { tag: 'Education', label: this.$t('message.pageArticlesTagEducation') },
        { tag: 'English', label: this.$t('message.pageArticlesTagEnglish') },
        { tag: 'Entertainment', label: this.$t('message.pageArticlesTagEntertainment') },
        { tag: 'French', label: this.$t('message.pageArticlesTagFrench') },
        { tag: 'Health', label: this.$t('message.pageArticlesTagHealth') },
        { tag: 'Lifestyle', label: this.$t('message.pageArticlesTagLifestyle') },
        { tag: 'Moments', label: this.$t('message.pageArticlesTagMoments') },
        { tag: 'Songs', label: this.$t('message.pageArticlesTagSongs') },
        { tag: 'Sports', label: this.$t('message.pageArticlesTagSports') },
        { tag: 'Technology', label: this.$t('message.pageArticlesTagTechnology') },
      ],

      tag: null,

      articles: [],
      pageSize: 5,
      pageKey: null,
      noMoreElements: false,
    }
  },

  setup() {
    const router = useRouter();
    const { t } = useI18n();

    const infiniteKey = ref(0);

    const forceRender = () => {
      infiniteKey.value += 1;
    };

    const viewArticle = (articleId) => {
      // Use router.push() to navigate
      router.push({
        path: `/view_article`,
        query: { id: articleId }
      });
    };

    const viewCharacter = (characterId) => {
      let path = "/view_character"

      if (adminCommon.isAdministrationPage()) {
        path += "_admin";
      }

      router.push({
        path: path,
        query: { id: characterId }
      });
    };

    return {
      forceRender,
      infiniteKey,
      viewArticle,
      viewCharacter,
    };
  },

  watch: {
    theme(theme) {
      console.log(`theme changed: ${theme}`);
      document.documentElement.setAttribute(
          'theme', theme);
    },
    locale(loc) {
      console.log(`locale changed: ${loc}`);
      this.$i18n.locale = loc
    },
    displayHeight: function () {
      if (this.docHeight > this.displayHeight) {
        this.showFooter = false;
      } else {
        this.showFooter = true;
      }
    }
  },

  computed: {
  },

  mounted: function () {
    console.log(`locale: ${JSON.stringify(this.$route.query.locale)}`);
    console.log(`theme: ${JSON.stringify(this.$route.query.theme)}`);
    if (this.query.theme) {
      this.theme = this.query.theme
    }

    if (this.query.locale) {
      this.locale=this.query.locale
    }

    if (this.query.style) {
      this.style=this.query.style
    }

    window.onresize = () => {
      return (()=> {
        this.displayHeight = document.documentElement.clientHeight;
      })();
    }

    this.$el.querySelectorAll('input').forEach(function (element) {
      element.setAttribute('autocomplete', 'off');
    });

  },

  methods: {
    chipVariant(chip) {
      if (chip.tag === this.tag) {
        return "flat"
      } else {
        return "tonal"
      }
    },

    imageAsset(article) {
      let urlPrefix = configs.apiUrlPrefix;

      let image_src = article.image
      // console.log(`image src: ${image_src}`)
      if (image_src && !image_src.startsWith("http")) {
        image_src = urlPrefix + '/' + image_src;
      }
      // console.log(`refined image src: ${image_src}`)

      return image_src
    },

    articleAudioAsset: function(article) {
      if (article && article.assets) {
        console.log(`article assets: ${JSON.stringify(article.assets)}`);

        const randomIndex = Math.floor(Math.random() * article.assets.length);
        let audioAsset = article.assets[randomIndex];
        if (audioAsset.type === 'audio') {
          return audioAsset.url
        }
      }

      return null
    },

    articleImages(article) {
      if (!article || !article.sections || article.sections.length === 0) {
        return [];
      }

      const text = article.sections[0].markdown

      const regex = /!\[.*?\]\((.*?)\)/g;
      let matches;
      const links = [];

      // Iterate over all matches
      while ((matches = regex.exec(text)) !== null) {
        links.push(matches[1]);
      }

      return links;
    },

    articleImagesCols(article) {
      const images = this.articleImages(article);
      if (!images || !images.length) {
        return 12;
      }

      const countOfImages = images.length;
      if (countOfImages % 3 === 0) {
        return 4
      } else if (countOfImages % 2 === 0) {
        return 6
      } else {
        return 12
      }

    },

    formatDate(datetimeString) {
      const datetime = DateTime.fromISO(datetimeString);

      return datetime.toISODate();
    },

    toggleArticle(articleIndex) {
      const updatedItems = [...this.articles];

      updatedItems[articleIndex] = { ...updatedItems[articleIndex], expand: !updatedItems[articleIndex].expand };
      this.articles = updatedItems;
    },

    listArticlesByTag: async function(tag) {
      console.log(`list articles by tag: ${tag}`);

      this.tag = tag;
      this.articles = [];
      this.noMoreElements = false;
      this.pageKey = null;
      // this.$emit('load', {side: 'end'})
      this.forceRender()
    },

    listArticles: async function ({ done }) {
      console.log(`list articles: no more = ${this.noMoreElements}`);
      if (this.noMoreElements) {
        done('empty')
        return
      }
      let urlPrefix = configs.apiUrlPrefix;
      console.log(`urlPrefix: ${urlPrefix}`);

      let url = urlPrefix + `/v1/creativepulse/articles`;
      console.log(`url: ${url}`);

      url +=`?page_size=${this.pageSize}`
      if (this.pageKey) {
        url += `&page_key=${this.pageKey}`
      }

      if (this.tag) {
        url += `&tag=${this.tag}`
      }

      try {
        let ret = await axios.get(url, {
        });

        if (ret && ret.data && ret.data.code === 200) {
          console.log(`ret: [${JSON.stringify(ret.data)}]`);

          for (let i = 0; i < ret.data.articles.length; i++){
            let item = ret.data.articles[i]
            item.expand = false
            this.articles.push(item)
          }

          this.pageKey = ret.data.nextPage
          if (ret.data.nextPage) {
            console.log('status: ok')
            done('ok')
          } else {
            console.log('status: empty')
            done('empty')
            this.noMoreElements = true
          }
        } else {
          this.errorMessage = this.$t('message.errorFailedToCallApi');
          done('error')
        }
      } catch (e) {
        console.error(`failed to call apply api: ${e}`);
        this.errorMessage = this.$t('message.errorFailedToCallApi');
        done('error')
      }
    },

    getElapseTime: function (publishedDateTime) {
      const publishedDate = new Date(publishedDateTime);

      // Get the current date and time
      const currentDate = new Date();

      // Calculate the time difference in milliseconds
      const timeDiff = currentDate.getTime() - publishedDate.getTime();

      // Convert the time difference to hours
      const hoursDiff = timeDiff / (1000 * 60 * 60);

      // Check if the elapsed time is less than 24 hours
      if (hoursDiff < 24) {
        // Round the elapsed time to the nearest hour and return as a string
        return Math.round(hoursDiff) + this.$t('message.pageArticlesTimeElapseHour');
      } else {
        // Calculate the elapsed time in days and return as a string
        const daysDiff = Math.floor(hoursDiff / 24);
        return daysDiff + this.$t('message.pageArticlesTimeElapseDay');
      }
    }
  }
}
</script>

<style scoped>
#app {
  margin: 0;
}

.app-name {
  margin: 0;
}
.app-name .col {
  padding-bottom: 0 !important;
}

.app-name-1 {
  color: #C1272D;
}

.v-list {
  padding-top: 0 !important;
}

.news_frame {
  padding: 0;
}

.v-infinite-scroll {
  padding-bottom: 96px;
}

.news_frame list {

}

.news_frame .v-list-item {
  padding: 0 !important;
}

.news_frame .v-card {
  box-shadow: unset !important;
  border-radius: 0 !important;
}

.asset-image {
  margin: 0 8px;
  border-radius: 8px !important;
  object-fit: fill !important;
  height: 260px !important;
}

.v-card {
  max-width: 100% !important;
  margin-bottom: 10px;
  --v-medium-emphasis-opacity: .85 !important;
}

.v-card-title {
  text-align: left;
  font-size: 20px !important;
  line-height: unset !important;
  font-weight: bold;
  overflow: hidden;
  white-space: normal;
  word-wrap: normal;
}


.v-card-subtitle {
  text-align: left;
  font-size: 12px !important;
  padding: 0 16px 8px 16px;
}

.v-card-text {
  font-size: 14px !important;
  text-align: left;
  padding-top: 0 !important;
}

.v-btn.v-size--default {
  color: var(--primary-color);
  font-size: 10px !important;
}

.providers a {
  padding-right: 5px;
}

.providers {
  padding-left: 0;
}

.portrait {
  flex-grow: unset;
}

.portrait .v-avatar {
  border: 1px solid lightgrey;
}

.tags-group {
  margin: 0 0 -16px;
}

.tags-group .v-col {
  padding: 8px 16px;
}

.chip-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.chip-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.chip-item {
  flex: 0 0 auto;
  font-size: 12px !important;
}

.article-moments {
  font-size: 14px !important;
  text-align: left;
}

.article-moments-abstract {
  padding: 0 16px 8px;
}

.article-moments-images {
  margin: 0;
  padding: 0 14px;
}

.article-moments-image {
  padding: 2px;
}

.article-moments-image {
  border-radius: 8px !important;
  object-fit: fill !important;
}

.audio-player {
  height: 40px;
  margin-top: 10px;
}

.shrink-image-container {
  overflow: hidden; /* Ensure that the image is clipped when it enlarges */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px !important;
}

.shrink-on-hover {
  border-radius: 8px !important;
  width: 100%; /* Ensure the image fits the container initially */
  height: 100%;
  transform: scale(1.2); /* Shrink the image */
  transition: transform 0.8s ease; /* Smooth transition */
}

.shrink-on-hover:hover {
  transform: scale(1); /* Shrink the image */
}

</style>
