<script setup>
import {useI18n} from "vue-i18n";
import {ref} from "vue";

const { t, tm } = useI18n();


function getRandomQuote(quotes) {
  console.log(`quotes: ${JSON.stringify(quotes)}`);
  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex];
}

const quotes = ref(tm('message.bottomLineQuotes'));
const randomQuote = ref(getRandomQuote(quotes.value));

randomQuote.value = getRandomQuote(quotes.value);

</script>

<template>
  <v-row class="bottom-line">
    <v-col
        class="bottom-line-quote"
        align-self="start"
        cols="1" md="1">
      <img src="@/assets/298854_quote_icon.png"/>
    </v-col>
    <v-col
        cols="11" md="11">
      <div
          class="bottom-line-content"
      >
        <span>{{randomQuote.content}}</span>
      </div>
      <div
          class="bottom-line-author">
        <span>{{ $t('message.bottomLineQuotePrefix')}} {{randomQuote.author}}</span>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
.bottom-line {
  text-align: left;
  font-size: 14px;
  font-style: italic;
  color: #9e9e9e;

  margin: 0;
  padding: 6px;

  background: #f9f9f9;
  border-radius: 8px;
}

.bottom-line .v-row {
  margin: 0;
}

.bottom-line-content {
  padding: 0;
}

.bottom-line-author {
  text-align: right;
  padding: 4px;
  font-size: 12px;
}

.bottom-line-quote {
  padding: 8px;
}

.bottom-line-quote img {
  width: 12px;
}

</style>
