/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
// import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { mdi } from 'vuetify/iconsets/mdi'
import { createVuetify } from 'vuetify'
import {h} from "vue";

import appTheme from '../theme.js'
import checkIcon from "@/components/icons/CheckIcon.vue";
import uncheckIcon from "@/components/icons/UncheckIcon.vue";
import radioCheckIcon from "@/components/icons/RadioCheckIcon.vue";
import radioUncheckIcon from "@/components/icons/RadioUncheckIcon.vue";

const customSvgNameToComponent = {
  checkIcon,
  uncheckIcon,
  radioCheckIcon,
  radioUncheckIcon
};

const customSVGs = {
  component: (props) => h(props.tag, [h(customSvgNameToComponent[props.icon], { class: 'v-icon__svg' })]),
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'appTheme',
    themes: {
      appTheme
    },
  },
  icons: {
    defaultSet: "mdi",
    sets: {
      mdi,
      custom: customSVGs,
    },
  },
})
