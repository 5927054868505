<template>
  <v-app class="oms-app">
    <Navigation/>
    <v-main class="character_frame" fluid>
      <v-dialog activator="#character_creation" max-width="340">
        <template v-slot:default="{ isActive }">
          <v-card
              :title='$t("message.pageCharactersCreationTitle")'
          >
            <template v-slot:prepend>
              <img
                  width="32"
                  style="margin: 0 10px"
                  src="@/assets/9026121_user_plus_icon.svg" />
            </template>
            <template v-slot:actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="ml-auto"
                  :text='$t("message.commonActionCreate")'
                  @click="isActive.value = false; this.createCharacter()"
              ></v-btn>
              <v-btn
                  class="ml-auto"
                  :text='$t("message.commonActionCancel")'
                  @click="isActive.value = false"
              ></v-btn>
            </template>
            <v-col class="cc-customization">
              <v-row
                  class="cc-option-title"
                  align-content="center"
              >
              <v-checkbox
                    true-icon="custom:checkIcon"
                    false-icon="custom:uncheckIcon"
                    :label="$t('message.pageCharactersRegionLabel')"
                    v-model="ccRegionEnabled">
                </v-checkbox>
                <img  src="@/assets/5969403_disease_distribution_epidemic_map_world_icon.svg" />
              </v-row>
              <v-combobox
                  ref="combobox"
                  :disabled="!ccRegionEnabled"
                  :label="$t('message.pageCharactersRegionLabel')"
                  :items="this.ccRegions"
                  item-title="name"
                  item-value="value"
                  v-model="ccRegion"
              >
              </v-combobox>
              <v-row
                  class="cc-option-title"
                  align-content="center"
              >
                <v-checkbox
                    true-icon="custom:checkIcon"
                    false-icon="custom:uncheckIcon"
                    :label="$t('message.pageCharactersOccupationLabel')"
                    v-model="this.ccOccupationEnabled">
                </v-checkbox>
                <img  src="@/assets/5969403_disease_distribution_epidemic_map_world_icon.svg" />
              </v-row>
              <v-combobox
                  :disabled="!this.ccOccupationEnabled"
                  :label="$t('message.pageCharactersOccupationLabel')"
                  :items="this.ccOccupations"
                  item-title="name"
                  item-value="value"
                  v-model="this.ccOccupation"
              >
              </v-combobox>
              <v-row
                  class="cc-option-title"
                  align-content="center"
              >
                <v-checkbox
                    true-icon="custom:checkIcon"
                    false-icon="custom:uncheckIcon"
                    :label="$t('message.pageCharactersGenderLabel')"
                    v-model="this.ccGenderEnabled">
                </v-checkbox>
                <img  src="@/assets/8893848_couple_actors_movie_avatar_cinema_icon.svg" />
              </v-row>
              <v-radio-group
                  inline
                  :disabled="!this.ccGenderEnabled"
                  v-model="this.ccGender"
              >
                <v-radio
                    true-icon="custom:radioCheckIcon"
                    false-icon="custom:radioUncheckIcon"
                    :label="$t('message.pageCharactersGenderMale')"
                    value="male">
                </v-radio>
                <v-radio
                    true-icon="custom:radioCheckIcon"
                    false-icon="custom:radioUncheckIcon"
                    :label="$t('message.pageCharactersGenderFemale')"
                    value="female">
                </v-radio>
              </v-radio-group>
              <v-checkbox
                  class="cc-option-title"
                  true-icon="custom:checkIcon"
                  false-icon="custom:uncheckIcon"
                  :label="$t('message.pageCharactersAgeLabel')"
                  v-model="this.ccAgeEnabled">
              </v-checkbox>
              <v-slider
                  v-model="this.ccAge"
                  :disabled="!this.ccAgeEnabled"
                  :min="this.ccAgeMin"
                  :max="this.ccAgeMax"
                  step="1"
                  :thumb-label="this.ccAgeThumb"
              ></v-slider>
            </v-col>

          </v-card>
        </template>
      </v-dialog>
      <v-infinite-scroll
          :items="characters"
          :key="infiniteKey"
          @load="listCharacters">
        <template v-slot:empty>
          <BottomLine/>
        </template>
        <v-list-item v-for="(characterChunk, index) in chunkedCharacters" :key="index">
          <v-row>
            <v-col
                class="character_slot"
                :style="{width: this.cardWidth}"
                v-for="(character, i) in characterChunk" :key="i">
                <v-card
                    variant="elevated"
                    class="mx-auto"
                    max-width="344"
                    v-on:click="viewCharacter(character.id)"
                >
                  <div class="asset-image shrink-image-container">
                    <v-lazy-image
                        class="shrink-on-hover"
                        :src="imageAsset(character)"
                        :src-placeholder="this.placeHolderImage()"
                        v-show="imageAsset(character) !== null"
                        cover
                    >

                    </v-lazy-image>
                  </div>
                <v-card-title class="name" >
                  {{character.display_name || character.name}}
                </v-card-title>

                <v-card-subtitle>
                  {{capitalize(character.occupation)}}
                </v-card-subtitle>
                <v-row
                    align="center"
                    justify="center"
                    class="character_location">
                  <v-col
                      cols="2"
                      md="2"
                  >
                    <country-flag :country='countryCode(character.country)' size='small'></country-flag>
                  </v-col>
                  <v-col
                      cols="9"
                      md="9"
                      class="d-flex align-items-center justify-start"
                  >
                    <span>{{ character.country + ", " + character.city }}</span>
                  </v-col>
                  <v-spacer
                      cols="1"
                      md="1"
                  ></v-spacer>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-list-item>
      </v-infinite-scroll>
      <v-fab
          :active="isAdmin"
          rounded="circle"
          location="end"
          class="fab"
          size="48"
          id="character_creation"
      >
        <img
            v-if="this.characterGenExecution == null"
            style="padding: 8px"
            src="@/assets/9026121_user_plus_icon.svg" />
        <v-progress-circular
            v-else
            :size="25"
            :width="3"
            indeterminate
        ></v-progress-circular>
      </v-fab>
    </v-main>
  </v-app>
</template>

<script>
import * as adminCommon from '../common/admin.js'
import configs from "../configs";
import {DateTime} from "luxon";
import axios from 'axios'
import {useRouter} from "vue-router";
import PlaceholderImage from '../assets/628287_anonym_avatar_default_head_person_icon.png'
import CountryFlag from 'vue-country-flag-next'
import { getCode } from 'country-list';
import VLazyImage from "v-lazy-image";
import {computed, ref, watch} from "vue";
import { useStore } from 'vuex';
import {useI18n} from "vue-i18n";
import Navigation from "@/components/Navigation.vue";
import BottomLine from "@/components/BottomLine.vue";

export default {
  name: 'Characters',
  components: {
    BottomLine,
    Navigation,
    CountryFlag,
    VLazyImage,
  },
  metaInfo() {
    return {
      title: this.$t('message.pageNewsAppName'),
    }
  },
  data() {
    return {
      fullPath: this.$route.fullPath,
      query: this.$route.query,
      theme: 'light',
      locale: 'en',
      docHeight: document.documentElement.clientHeight,
      displayHeight: document.documentElement.clientHeight,
      showFooter: true,
      errorMessage: null,
      style: '0',

      characters: [],
      characterPerLine: 2,
      pageKey: null,
      noMoreElements: false,

      ccOccupations: [
        {
          name: this.$t('message.pageCharactersOccupationAgriculture'),
          value: "Agriculture",
        },
        {
          name: this.$t('message.pageCharactersOccupationAthletes'),
          value: "Athletes",
        },
        {
          name: this.$t('message.pageCharactersOccupationConstruction'),
          value: "Construction",
        },
        {
          name: this.$t('message.pageCharactersOccupationArts'),
          value: "Arts",
        },
        {
          name: this.$t('message.pageCharactersOccupationBusiness'),
          value: "Business",
        },
        {
          name: this.$t('message.pageCharactersOccupationEducation'),
          value: "Education",
        },
        {
          name: this.$t('message.pageCharactersOccupationFinance'),
          value: "Finance",
        },
        {
          name: this.$t('message.pageCharactersOccupationGovernment'),
          value: "Government",
        },
        {
          name: this.$t('message.pageCharactersOccupationHealthcare'),
          value: "Healthcare",
        },
        {
          name: this.$t('message.pageCharactersOccupationHospitality'),
          value: "Hospitality",
        },
        {
          name: this.$t('message.pageCharactersOccupationServices'),
          value: "Services",
        },
        {
          name: this.$t('message.pageCharactersOccupationIT'),
          value: "IT",
        },
        {
          name: this.$t('message.pageCharactersOccupationLaw'),
          value: "Law",
        },
        {
          name: this.$t('message.pageCharactersOccupationManufacturing'),
          value: "Manufacturing",
        },
        {
          name: this.$t('message.pageCharactersOccupationMarketing'),
          value: "Marketing",
        },
        {
          name: this.$t('message.pageCharactersOccupationSTEM'),
          value: "STEM",
        },
        {
          name: this.$t('message.pageCharactersOccupationTransportation'),
          value: "Transportation",
        },
        {
          name: this.$t('message.pageCharactersOccupationCatering'),
          value: "Catering",
        }
      ],

      ccAgeMin: 14,
      ccAgeMax: 80,

      ccAge: null,
      ccGender: null,
      ccOccupation: null,
      ccAgeEnabled: false,
      ccGenderEnabled: false,
      ccOccupationEnabled: false,

      characterGenExecution: null,
      characterGenCheckHandler: null,

      executionCheckInterval: 10000,

    }
  },

  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const token = computed(() => store.getters.token);
    console.log(`token: ${JSON.stringify(token.value)}`);

    const viewCharacter = (characterId) => {
      let path = "/view_character"

      if (adminCommon.isAdministrationPage()) {
        path += "_admin";
      }

      router.push({
        path: path,
        query: { id: characterId }
      });
    };


    const infiniteKey = ref(0);

    const forceRender = () => {
      infiniteKey.value += 1;
    };

    const isAdmin = ref(store.getters.isAdmin);

    const ccRegions = [
      {
        name: t('message.pageCharactersRegionEurope'),
        value: "Europe",
      },
      {
        name: t('message.pageCharactersRegionAfrica'),
        value: "Africa",
      },
      {
        name: t('message.pageCharactersRegionAsia'),
        value: "Asia",
      },
      {
        name: t('message.pageCharactersRegionFrance'),
        value: "France",
      },
      {
        name: t('message.pageCharactersRegionSpain'),
        value: "Spain",
      },
      {
        name: t('message.pageCharactersRegionRomania'),
        value: "Romania",
      },
      {
        name: t('message.pageCharactersRegionPoland'),
        value: "Poland",
      },
      {
        name: t('message.pageCharactersRegionChina'),
        value: "China",
      }
    ];

    const ccRegion = ref(null);
    const ccRegionEnabled = ref(false);
    watch(ccRegionEnabled, (enabled) => {
      if (enabled) {
        ccRegion.value = ccRegions[0];
      } else {
        ccRegion.value = null;
      }
    });
    watch(ccRegion, (value) => {
      console.log(`region new value: ${JSON.stringify(value)}`);
    });

    return {
      token,
      viewCharacter,
      forceRender,
      infiniteKey,
      isAdmin,
      ccRegion,
      ccRegions,
      ccRegionEnabled
    };
  },

  watch: {
    theme(theme) {
      console.log(`theme changed: ${theme}`);
      document.documentElement.setAttribute(
          'theme', theme);
    },
    locale(loc) {
      console.log(`locale changed: ${loc}`);
      this.$i18n.locale = loc
    },
    displayHeight: function () {
      if (this.docHeight > this.displayHeight) {
        this.showFooter = false;
      } else {
        this.showFooter = true;
      }
    },
    ccGenderEnabled: function (enabled) {
      if (enabled) {
        this.ccGender = "male";
      } else {
        this.ccGender = null;
      }

    },
    ccOccupationEnabled: function (enabled) {
      if (enabled) {
        this.ccOccupation = this.ccOccupations[0];
      } else {
        this.ccOccupation = null;
      }

    },
    ccAgeEnabled: function (enabled) {
      if (enabled) {
        this.ccAge = Math.round((this.ccAgeMax - this.ccAgeMin) / 2) + this.ccAgeMin;
      } else {
        this.ccAge = null;
      }
    }
  },

  computed: {

    pageSize() {
      return this.characterPerLine * 3
    },

    cardWidth() {
      return Math.floor(100 / this.characterPerLine) + '%';
    },

    chunkedCharacters() {
      if (!this.characters) {
        return [];
      }

      const chunks = [];
      for (let i = 0; i < this.characters.length; i += this.characterPerLine) {
        chunks.push(this.characters.slice(i, i + this.characterPerLine));
      }
      return chunks;
    },

    ccAgeThumb() {
      if (this.ccAgeEnabled) {
        return "always"
      } else {
        return false;
      }
    },
  },

  mounted: function () {
    console.log(`admin: ${JSON.stringify(this.isAdmin)}`);
    console.log(`locale: ${JSON.stringify(this.$route.query.locale)}`);
    console.log(`theme: ${JSON.stringify(this.$route.query.theme)}`);
    if (this.query.theme) {
      this.theme = this.query.theme
    }

    if (this.query.locale) {
      this.locale=this.query.locale
    }

    if (this.query.style) {
      this.style=this.query.style
    }

    window.onresize = () => {
      return (()=> {
        this.displayHeight = document.documentElement.clientHeight;
      })();
    }

    this.$el.querySelectorAll('input').forEach(function (element) {
      element.setAttribute('autocomplete', 'off');
    });

  },

  methods: {
    capitalize(str) {
      if (!str) return str; // check for empty string and return it as is
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },

    countryCode(country) {
      if (country === 'Russia') {
        country = 'Russian Federation'
      } else if (country === 'United Kingdom') {
        country = 'United Kingdom of Great Britain and Northern Ireland'
      }
      return getCode(country)
    },

    placeHolderImage() {
      return PlaceholderImage;
    },

    imageAsset(character) {
      let urlPrefix = configs.apiUrlPrefix;

      let image_src = character.portrait_thumb || character.portrait
      if (!image_src) {
        image_src = PlaceholderImage
      } else {
        if (!image_src.startsWith("http")) {
          image_src = urlPrefix + '/' + image_src;
        }
      }
      // console.log(`image src: ${image_src}`)
      // console.log(`refined image src: ${image_src}`)

      return image_src
    },

    briefCharacter(character) {
      let subject = "";
      if (character.gender === 'Male') {
        subject = "He"
      } else {
        subject = "She"
      }

      return `${subject} is a ${character.occupation} from ${character.city}, ${character.country}.`
    },

    formatDate(datetimeString) {
      const datetime = DateTime.fromISO(datetimeString);

      return datetime.toISODate();
    },

    toggleArticle(articleIndex) {
      const updatedItems = [...this.characters];

      updatedItems[articleIndex] = { ...updatedItems[articleIndex], expand: !updatedItems[articleIndex].expand };
      this.characters = updatedItems;
    },

    listCharacters: async function ({ done }) {
      console.log(`list characters: no more = ${this.noMoreElements}`);
      if (this.noMoreElements) {
        done('empty')
        return
      }
      let urlPrefix = configs.apiUrlPrefix;
      console.log(`urlPrefix: ${urlPrefix}`);

      let url = urlPrefix + `/v1/creativepulse/characters`;
      console.log(`url: ${url}`);

      url +=`?page_size=${this.pageSize}`
      if (this.pageKey) {
        url += `&page_key=${this.pageKey}`
      }

      try {
        let ret = await axios.get(url, {
        });

        if (ret && ret.data && ret.data.code === 200) {
          console.log(`ret: [${JSON.stringify(ret.data)}]`);

          for (let i = 0; i < ret.data.characters.length; i++){
            let item = ret.data.characters[i]
            item.expand = false
            this.characters.push(item)
          }

          this.pageKey = ret.data.nextPage
          if (ret.data.nextPage) {
            console.log('status: ok')
            done('ok')
          } else {
            console.log('status: empty')
            done('empty')
            this.noMoreElements = true
          }
        } else {
          this.errorMessage = this.$t('message.errorFailedToCallApi');
          done('error')
        }
      } catch (e) {
        console.error(`failed to call apply api: ${e}`);
        this.errorMessage = this.$t('message.errorFailedToCallApi');
        done('error')
      }
    },

    createCharacter: async function() {
      console.log(`token: ${this.token}`)
      if (!this.isAdmin) {
        console.error(`no permit to run this in non-admin page.`)
        return;
      }

      let urlPrefix = configs.cloudFunctionPrefix;
      console.log(`urlPrefix: ${urlPrefix}`);

      let url = urlPrefix + `/v1/character/create`;
      console.log(`url: ${url}`);

      this.characterGenExecution = "";

      let data = {};

      if (this.ccRegion) {
        console.log(`ccRegion: ${JSON.stringify(this.ccRegion)}`);
        if (typeof this.ccRegion === "object") {
          data.region = this.ccRegion.value;
        } else {
          data.region = this.ccRegion;
        }
      }

      if (this.ccGender) {
        data.gender = this.ccGender;
      }

      if (this.ccAge) {
        data.age = this.ccAge;
      }

      if (this.ccOccupation) {
        console.log(`ccOccupation: ${JSON.stringify(this.ccOccupation)}`);
        if (typeof this.ccOccupation === "object") {
          data.occupation = this.ccOccupation.value;
        } else {
          data.occupation = this.ccOccupation;
        }
      }

      console.log(`data: ${JSON.stringify(data)}`);

      try {
        let ret = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });

        if (ret && ret.data && ret.data.code === 200) {
          console.log(`character creation is requested.`);

          this.characterGenExecution = ret.data.execution;
          this.characterGenCheckHandler = setInterval(
              this.checkExecution,
              this.executionCheckInterval,
              this.characterGenExecution,
              this.genCharacterDone);
        } else {
          this.errorMessage = this.$t('message.errorFailedToCallApi');
          this.cleanGenCharacter();
        }
      } catch (e) {
        console.error(`failed to call create character api: ${e}`);
        this.errorMessage = this.$t('message.errorFailedToCallApi');
        this.cleanGenCharacter();
      }
    },

    genCharacterDone: function (status) {
      console.log(`character gen status: ${status}`);

      if (status === 'succeed') {
        this.characters = [];
        this.noMoreElements = false;
        this.pageKey = null;
        // this.$emit('load', {side: 'end'})
        this.forceRender()
      }

      this.cleanGenCharacter()
    },

    cleanGenCharacter: function () {
      if (this.characterGenCheckHandler) {
        clearInterval(this.characterGenCheckHandler)
        this.characterGenCheckHandler = null;
      }

      this.characterGenExecution = null;
    },

    checkExecution: async function (executionId, done) {
      console.log(`checking status of execution [${executionId}].`);

      let urlPrefix = configs.cloudFunctionPrefix;
      console.log(`urlPrefix: ${urlPrefix}`);

      let url = urlPrefix + `/v1/execution/status?id=${executionId}`;
      console.log(`url: ${url}`);

      try {
        let ret = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });

        if (ret && ret.data && ret.data.code === 200) {
          console.log(`[${JSON.stringify(ret.data)}]`);
          if (ret.data.status === 'succeed' || ret.data.status === 'failed') {
            done(ret.data.status);
          }
        } else {
          this.errorMessage = this.$t('message.errorFailedToCallApi');
        }
      } catch (e) {
        console.error(`failed to call get execution status api: ${e}`);
        this.errorMessage = this.$t('message.errorFailedToCallApi');
      }
    },



    getElapseTime: function (publishedDateTime) {
      const publishedDate = new Date(publishedDateTime);

      // Get the current date and time
      const currentDate = new Date();

      // Calculate the time difference in milliseconds
      const timeDiff = currentDate.getTime() - publishedDate.getTime();

      // Convert the time difference to hours
      const hoursDiff = timeDiff / (1000 * 60 * 60);

      // Check if the elapsed time is less than 24 hours
      if (hoursDiff < 24) {
        // Round the elapsed time to the nearest hour and return as a string
        return Math.round(hoursDiff) + this.$t('message.pageArticlesTimeElapseHour');
      } else {
        // Calculate the elapsed time in days and return as a string
        const daysDiff = Math.floor(hoursDiff / 24);
        return daysDiff + this.$t('message.pageArticlesTimeElapseDay');
      }
    }
  }
}
</script>

<style scoped>

#app {
  margin: 0;
}

.app-name {
  margin: 0;
}
.app-name .col {
  padding-bottom: 0 !important;
}

.app-name-1 {
  color: #C1272D;
}

.v-list {
  padding-top: 0 !important;
}

.character_frame {
  padding: 16px !important;
}

.v-infinite-scroll {
  padding-bottom: 96px;
}

.character_frame list {
}

.character_frame .v-list-item {
  padding: 0 !important;
}

.character_frame .v-card {
  max-width: 100% !important;
  margin-bottom: 10px;

}

.asset-image {
  object-fit: cover !important;
  height: 160px !important;
}

.v-card-title {
  text-align: left;
  font-size: 12px !important;
  line-height: unset !important;
  font-weight: bold;
  overflow: hidden;
  white-space: normal;
  word-wrap: normal;
  padding: 4px 12px 0 !important;
}

.v-card-subtitle {
  text-align: left;
  font-size: 12px !important;
  font-style: italic;
  color: #1b1b1b !important;
  padding: 0 12px 8px 12px;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: unset;
}

.v-card-text {
  font-size: 14px !important;
  text-align: left;
  padding-top: 0 !important;
}

.v-btn.v-size--default {
  color: var(--primary-color);
  font-size: 10px !important;
}

.providers a {
  padding-right: 5px;
}

.providers {
  padding-left: 0;
}

.portrait {
  flex-grow: unset;
}

.character_slot {
  flex-grow: 1;
}

.character_location {
  font-size: 10px;
  padding: 0 0 0 2px;
  margin: 0;
}

.character_location div {
  padding: 0;
  margin: 0;
}

.character_location span {
  padding-left: 4px;
  padding-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
}

.flag {
  margin-left: -1em !important;
  margin-bottom: -1.2em !important;
  margin-top: -1.2em !important;
}

.v-fab {
  position: fixed;
  bottom: 64px;
  right: 72px;
}


.v-fab :deep(button) {
  border: 2px solid #3d3d3d
}

.name {
  white-space: nowrap;
}

.cc-customization .v-input {
  margin: 0 16px;
}

.cc-customization .v-checkbox :deep(.v-selection-control)  {
  min-height: 20px !important;
}

.cc-customization .v-slider {
  margin: 6px 36px !important;
}

.cc-option-title {
  margin: 0 0 -16px;
}

.cc-option-title :deep(label) {
  color: black;
  opacity: unset;
  font-weight: bold;
  font-size: 18px !important;
}

.cc-option-title img {
  display: none;
  width: 32px;
  height: 32px;
}

.shrink-image-container {
  overflow: hidden; /* Ensure that the image is clipped when it enlarges */
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px  !important;
  border-top-right-radius: 8px  !important;
}

.shrink-on-hover {
  border-top-left-radius: 8px  !important;
  border-top-right-radius: 8px  !important;
  width: 100%; /* Ensure the image fits the container initially */
  height: 100%;
  transform: scale(1.2); /* Shrink the image */
  transition: transform 0.8s ease; /* Smooth transition */
}

.shrink-on-hover:hover {
  transform: scale(1); /* Shrink the image */
}
</style>
