/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'
import { createWebHistory, createRouter } from 'vue-router'
import { createI18n } from 'vue-i18n'
import { createApp } from 'vue'
import {getCurrentUser, VueFire, VueFireAuth} from 'vuefire'

import './assets/common.scss';
import './assets/app.scss';

import App from './App.vue'
import Articles from './components/Articles.vue'
import ViewArticle from './components/ViewArticle.vue'
import Characters from "@/components/Characters.vue";
import ViewCharacter from "@/components/ViewCharacter.vue";
import Login from "@/components/Login.vue";
import Trending from "@/components/Trending.vue";
import ManageSchedules from "@/components/ManageSchedules.vue";

import { getFirebaseApp } from './firebasehelper'
import store from './store';

const routes = [
    { path: '/', redirect: '/articles' },
    { path: '/login', component: Login },
    { path: '/articles', component: Articles },
    { path: '/trending', component: Trending },
    { path: '/view_article', component: ViewArticle },
    { path: '/characters', component: Characters },
    { path: '/view_character', component: ViewCharacter },
    { path: '/schedule', component: ManageSchedules },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
})

router.beforeEach(async (to, from, next) => {
    // routes with `meta: { requiresAuth: true }` will check for
    // the users, others won't
    if (to.meta.requiresAuth) {
        const currentUser = await getCurrentUser()
        console.log(`user: ${JSON.stringify(currentUser)}`)
        // if the user is not logged in, redirect to the login page
        if (!currentUser) {
            next(`/login?redirect=${to.fullPath}`)
            // next()
        } else {
            next()
        }
    } else {
        next()
    }

})

const messages = {
    en: {
        message: {
            pageEchoTopText: "Hello",
            pageEchoBtnEcho: "Echo",
            pageEchoInputLabel: "Message",
            pageEchoInputHint: "Enter something you want to be echoed",

            pageNewsAppName: "Æditor - Creative News",
            pageNewsAppName1: "Æ",
            pageNewsAppName2: "ditor",

            pageNewsProvidersLeading: "According to",
            pageNewsTimeElapseHour: "h ago",
            pageNewsTimeElapseDay: "d ago",

            pageCampaignAppName: "Adviser - Creative Campaigns",
            pageCampaignAppName1: "Ad",
            pageCampaignAppName2: "viser",

            pageCampaignStyleLabelVector: "Vector",
            pageCampaignStyleLabelRealistic: "Realistic",
            pageCampaignColorLabelNone: "None",
            pageCampaignColorLabelOrange: "Orange",
            pageCampaignColorLabelRed: "Red",
            pageCampaignColorLabelGreen: "Green",
            pageCampaignColorLabelBlue: "Blue",
            pageCampaignOrientLabelLand: "Landscape",
            pageCampaignOrientLabelPort: "Portrait",
            pageCampaignOrientLabelSquare: "Square",

            pageCampaignCommonBtnMore: "Advanced settings",
            pageCampaignCommonBtnNext: "Next",
            pageCampaignCommonBtnBack: "Back",
            pageCampaignPageTopicLabel: "Your topic",
            pageCampaignPageTopicTargetLabel: "The campaign is for ...",
            pageCampaignPageTopicTargetHint: "E.g. our brand new smart phone",
            pageCampaignPageTopicExpLabel: "More about the target",
            pageCampaignPageTopicExpHint: "E.g. it is a phone with large screen",
            pageCampaignPageTopicAudienceLabel: "Target customers",
            pageCampaignPageTopicAudienceHint: "E.g. photography enthusiasts",
            pageCampaignPageTopicRepLabel: "Any image representatives?",
            pageCampaignPageTopicRepHint: "E.g. Elon Musk, Lara Croft",
            pageCampaignPageTopicCheckAdv: "Advanced",
            pageCampaignPageTopicBtnAdvise: "Advise",
            pageCampaignPagePlansLabel: "Plans setup",
            pageCampaignPagePlansCountDownPrompt: "Your plans properly arrive in ",
            pageCampaignPagePlansCountDownPromptLonger: "Your plans might arrive very soon",
            pageCampaignPagePlansAdvOrientation: "Orientation",
            pageCampaignPagePlansWidthLabel: "Width",
            pageCampaignPagePlansWidthHint: "width of adv. image",
            pageCampaignPagePlansHeightLabel: "Height",
            pageCampaignPagePlansHeightHint: "height of adv. image",
            pageCampaignPagePlansAdvStyle: "Image style",
            pageCampaignPagePlansAdvColor: "Color bias",
            pageCampaignPageVisLabel: "Visualization",
            pageCampaignPageVisCountDownPrompt1: "{arrived} of {total} images arrived",
            pageCampaignPageVisCountDownPrompt2: "Next image properly arrives in {countdown}s",
            pageCampaignPageVisCountDownPrompt2Longer: "Next image might arrive very soon",
            pageCampaignPageVisDownload: "Download",

            pageArticlesWrittenBy: "Written by",
            pageArticlesWritten: "Written ",
            pageArticlesTimeElapseHour: "h ago",
            pageArticlesTimeElapseDay: "d ago",
            pageArticlesTagAll: "All",
            pageArticlesTagWorldNes: "World News",
            pageArticlesTagPolitics: "Politics",
            pageArticlesTagBusiness: "Business",
            pageArticlesTagTechnology: "Technology",
            pageArticlesTagEntertainment: "Entertainment",
            pageArticlesTagSports: "Sports",
            pageArticlesTagHealth: "Health",
            pageArticlesTagScience: "Science",
            pageArticlesTagTravel: "Travel",
            pageArticlesTagLifestyle: "Lifestyle",
            pageArticlesTagEducation: "Education",
            pageArticlesTagOpinion: "Opinion",
            pageArticlesTagLocalNews: "Local News",
            pageArticlesTagWeather: "Weather",
            pageArticlesTagCrimeAndJustice: "Crime and Justice",
            pageArticlesTagRealEstate: "Real Estate",
            pageArticlesTagEnvironment: "Environment",
            pageArticlesTagAutomotive: "Automotive",
            pageArticlesTagFoodAndDrink: "Food and Drink",
            pageArticlesTagFinance: "Finance",
            pageArticlesTagArticles: "Articles",
            pageArticlesTagBlogs: "Blogs",
            pageArticlesTagMoments: "Moments",
            pageArticlesTagSongs: "Songs",
            pageArticlesTagApps: "Apps",
            pageArticlesTagFrench: "French",
            pageArticlesTagEnglish: "English",
            pageArticlesTagChinese: "Chinese",

            pageViewCharacterExpertise: "Expertise",
            pageViewCharacterInterests: "Interests",
            pageViewArticlesSectionTitle: "Articles",
            pageViewArticlesSectionTitleHis: "His articles",
            pageViewArticlesSectionTitleHer: "Her articles",
            pageViewArticleStepArticleDesc: "Generate article content from scratch",
            pageViewArticleStepReviewDesc: "Review and refine the article content",
            pageViewArticleStepAssetDesc: "Generate assets in the article",
            pageViewArticleStepsLabel: "Customize Steps",
            pageViewArticleStepsHint: "Steps",
            pageViewArticleActionRewrite: "Rewrite",
            pageViewArticleRewriteTitle: "Rewrite the article",

            pageSigningInPrompt: "Signing in your account ...",
            pageSignedInPrompt: "Signed in as: ",

            pageCharactersCreationTitle: "Create a new character",
            pageCharactersRegionLabel: "Region",
            pageCharactersRegionEurope: "Europe",
            pageCharactersRegionAfrica: "Africa",
            pageCharactersRegionAsia: "Asia",
            pageCharactersRegionFrance: "France",
            pageCharactersRegionSpain: "Spain",
            pageCharactersRegionRomania: "Romania",
            pageCharactersRegionPoland: "Poland",
            pageCharactersRegionChina: "China",
            pageCharactersAgeLabel: "Age",
            pageCharactersGenderLabel: "Gender",
            pageCharactersGenderMale: "Male",
            pageCharactersGenderFemale: "Female",
            pageCharactersOccupationLabel: "Occupation",
            pageCharactersOccupationAgriculture: "Agriculture",
            pageCharactersOccupationAthletes: "Athletes",
            pageCharactersOccupationConstruction: "Construction",
            pageCharactersOccupationArts: "Arts",
            pageCharactersOccupationBusiness: "Business",
            pageCharactersOccupationEducation: "Education",
            pageCharactersOccupationFinance: "Finance",
            pageCharactersOccupationGovernment: "Government",
            pageCharactersOccupationHealthcare: "Healthcare",
            pageCharactersOccupationHospitality: "Hospitality",
            pageCharactersOccupationServices: "Services",
            pageCharactersOccupationIT: "IT",
            pageCharactersOccupationLaw: "Law",
            pageCharactersOccupationManufacturing: "Manufacturing",
            pageCharactersOccupationMarketing: "Marketing",
            pageCharactersOccupationSTEM: "STEM",
            pageCharactersOccupationTransportation: "Transportation",
            pageCharactersOccupationCatering: "Catering",
            pageViewCharacterArticleCreationTitle: "Write an article",
            pageViewCharacterArticleSourceLabel: "Inspired by",
            pageViewCharacterArticleSourceHint: "Source",
            pageViewCharacterArticleSourceRandom: "Random",
            pageViewCharacterArticleGuide: "Suggestion",
            pageViewCharacterArticleLanguageLabel: "Language",
            pageViewCharacterArticleSongStyleLabel: "Style of the song",
            pageViewCharacterArticleSongStyleHint: "Style",
            pageViewCharacterArticleInstrumentalLabel: "Instrumental",
            pageViewCharacterArticlePromoteSingleFeatureLabel: "Promote Single Feature",
            pageViewCharacterArticleLyrics: "Lyrics",
            pageViewCharacterArticleLangEn: "English",
            pageViewCharacterArticleLangFr: "French",
            pageViewCharacterArticleLangSp: "Spanish",
            pageViewCharacterArticleLangZh: "Chinese",
            pageViewCharacterArticleType: "Type",
            pageViewCharacterArticleTypeKnowledge: "Knowledge Sharing",
            pageViewCharacterArticleTypeBlogpost: "Blog Post",
            pageViewCharacterArticleTypeMoments: "Moments",
            pageViewCharacterArticleTypeAlog: "Audio Blog",
            pageViewCharacterArticleTypeAppPromote: "App Promotion",
            pageViewCharacterArticleInstrumentalOnly: "Instruments Only",
            pageViewCharacterArticleWithLyrics: "With Lyrics",
            pageViewCharacterPickSource: "From Trending News",

            pageTrendingCollectingTitle: "Collect trending news",
            pageTrendingIPSLabel: "Item per source",
            pageTrendingLabelSites: "News sites",
            pageTrendingLabelNewSite: "Add a news site",
            pageTrendingLabelPages: "News pages",
            pageTrendingLabelCoverImageStrategy: "Cover image strategy",
            pageTrendingLabelCoverImageStrategyRandom: "Randomly Pick",
            pageTrendingLabelCoverImageStrategySpecify: "Specified index",
            pageTrendingLabelCoverImageIndex: "Cover image index",
            pageTrendingLabelNewPage: "Add a news page",
            pageTrendingActionCollect: "Collect",
            pageTrendingSourceTitleThePaper: "ThePaper (澎湃网)",
            pageTrendingSourceTitleToutiao: "Toutiao (今日头条)",
            pageTrendingSourceTitleChinaNews: "ChinaNews (中国新闻网)",
            pageTrendingSourceTitleBBC: "BBC News",
            pageTrendingSourceTitleCNN: "CNN News",
            pageTrendingSourceTitleBBCSport: "BBC Sport - Paris 2024",
            pageTrendingSourceTitleRedditAfrica: "Reddit - Africa",
            pageTrendingSourceTitleGooglePlay: "Google Play",
            pageTrendingSourceTitleGooglePlayApps: "Google Play Apps",
            pageTrendingSourceTitleGooglePlayGames: "Google Play Games",
            pageTrendingEmptySourcePrompt: "Select at least one site or page",
            pageTrendingNewsProviderAll: "All",

            pageManageSchedulesTitle: "All Schedules",

            dialogScheduleLabelTime: "Run on",
            dialogScheduleHintTime: "Time",
            dialogScheduleMaxTimeSlots: "Max. time slots are ",
            dialogSchedulesTitle: "Schedules",
            dialogScheduleArticleTitle: "Schedule an article",
            dialogScheduleTrendingTitle: "Schedule collecting",
            dialogScheduleInspiredBySource: "Inspired by news",
            dialogScheduleActionAdd: "Add a schedule",
            dialogSchedulePeriodAM: "AM",
            dialogSchedulePeriodPM: "PM",

            navigationTitleEncyclopediaPart1: "AI",
            navigationTitleEncyclopediaPart2: "dem",
            navigationPageArticles: "Articles",
            navigationPageCharacters: "Characters",
            navigationPageTrendingNews: "Trending News",
            navigationPageSchedules: "Schedules",
            navigationAdminSwitch: "Administrator",

            bottomLineQuotePrefix: "by ",
            bottomLineQuotes: [
                {
                    content: "When one door closes, another opens; but we often look so long and so regretfully upon the closed door that we do not see the one which has opened for us.",
                    author: "Alexander Graham Bell"
                },
                {
                    content: "Every new beginning comes from some other beginning's end.",
                    author: "Seneca"
                },
                {
                    content: "In the end, everything will be okay. If it's not okay, it's not the end.",
                    author: "John Lennon"
                },
                {
                    content: "The darkest hour has only sixty minutes.",
                    author: "Morris Mandel"
                },
                {
                    content: "Hope is the thing with feathers that perches in the soul—and sings the tunes without the words—and never stops at all.",
                    author: "Emily Dickinson"
                },
                {
                    content: "Rock bottom became the solid foundation on which I rebuilt my life.",
                    author: "J.K. Rowling"
                },
                {
                    content: "Even the darkest night will end and the sun will rise.",
                    author: "Victor Hugo"
                },
                {
                    content: "Sometimes when you're in a dark place you think you've been buried, but actually you've been planted.",
                    author: "Christine Caine"
                },
                {
                    content: "When the world says 'Give up,' hope whispers, 'Try it one more time.'",
                    author: "Unknown"
                },
                {
                    content: "What feels like the end is often the beginning.",
                    author: "Unknown"
                }
            ],

            commonShareArticleTitle: "Share the article",
            commonShareSlogan: "Unleash the media creativity with AI",
            commonSharePreparingPrompt: "Preparing content ...",

            commonOptionLabelLanguage: "Language",
            commonOptionLangEn: "English",
            commonOptionLangFr: "French",
            commonOptionLangSp: "Spanish",
            commonOptionLangZh: "Chinese",
            commonOptionLabelArticleType: "Type",
            commonOptionArticleTypeKnowledge: "Knowledge Sharing",
            commonOptionArticleTypeBlogpost: "Blog Post",
            commonOptionArticleTypeMoments: "Moments",
            commonOptionArticleTypeAlog: "Audio Blog",
            commonOptionArticleTypeAppPromote: "App Promotion",

            commonPickingSourceRandomTitle: "Random",
            commonPickingSourceRandomAbstract: "You'd to randomly select an item in top 10 trending news.",
            commonFooterCopyright: "Orange Labs China. Copyright",
            commonActionCreate: "Create",
            commonActionSchedule: "Schedule",
            commonActionShare: "Share",
            commonActionCancel: "Cancel",
            commonActionBack: "Back",
            commonActionSignIn: "SIGN IN",
            commonActionSignOut: "SIGN OUT",
            commonVerQuerying: "Querying...",
            errorFailedToCallApi: "Failed to call API"
        }
    },
    cn: {
        message: {
            pageEchoTopText: "你好",
            pageEchoBtnEcho: "回显",
            pageEchoInputLabel: "消息",
            pageEchoInputHint: "输入任意想被回显的内容",

            commonFooterCopyright: "Orange中国创新实验室. 版权所有"
        }
    }
}

const i18n = createI18n({
    legacy: false,
    locale: 'en', // set locale
    messages: messages,
})

const app = createApp(App)
    .use(router)
    .use(i18n)
    .use(store)

app.use(VueFire, {
    firebaseApp: getFirebaseApp(),
    modules: [
        // ... other modules
        VueFireAuth(),
    ],
})

registerPlugins(app)

store.dispatch('fetchUser').then(async (user) => {
    if (user) {
        await store.dispatch('startTokenRefreshTimer');
    }
});


app.mount('#app')
