<template>
  <v-app class="oms-app">
    <Navigation/>
    <v-main
        class="news_frame"
        :class="articleType"
        fluid>
      <v-dialog
          v-model="articleDialog"
          activator="#article_generation" max-width="340">
        <template v-slot:default="{ isActive }">
          <v-card
              :title='$t("message.pageViewCharacterArticleCreationTitle")'
          >
            <template v-slot:prepend>
              <img
                  width="32"
                  style="margin: 0 10px"
                  src="@/assets/8666806_edit_write_pen_icon.svg" />
            </template>
            <template v-slot:actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="ml-auto"
                  :text='$t("message.commonActionCreate")'
                  @click="isActive.value = false; this.generateArticle()"
              ></v-btn>
              <v-btn
                  class="ml-auto"
                  :text='$t("message.commonActionCancel")'
                  @click="isActive.value = false; this.cleanGenArticleParameters()"
              ></v-btn>
            </template>
            <v-col class="ac-customization">
              <v-row
                  class="ac-option-title"
                  align-content="center"
              >
                <v-checkbox
                    true-icon="custom:checkIcon"
                    false-icon="custom:uncheckIcon"
                    :label="$t('message.pageViewCharacterArticleType')"
                    v-model="acTypeEnabled">
                </v-checkbox>
              </v-row>
              <v-select
                  :disabled="!acTypeEnabled"
                  :label="$t('message.pageViewCharacterArticleType')"
                  :items="this.acTypes"
                  item-title="label"
                  item-value="value"
                  v-model="acType"
              >
              </v-select>
              <div
                  v-show="acType === 'apppromote'"
              >
                <v-row
                    class="ac-option-title"
                    align-content="center"
                >
                  <v-checkbox
                      true-icon="custom:checkIcon"
                      false-icon="custom:uncheckIcon"
                      :label="$t('message.pageViewCharacterArticlePromoteSingleFeatureLabel')"
                      v-model="acPromoteSpecificFeature">
                  </v-checkbox>
                </v-row>
              </div>
              <v-row
                  class="ac-option-title"
                  align-content="center"
              >
                <v-checkbox
                    true-icon="custom:checkIcon"
                    false-icon="custom:uncheckIcon"
                    :label="$t('message.pageViewCharacterArticleLanguageLabel')"
                    v-model="acLanguageEnabled">
                </v-checkbox>
              </v-row>
              <v-select
                  :disabled="!acLanguageEnabled"
                  :label="$t('message.pageViewCharacterArticleLanguageLabel')"
                  :items="this.languages"
                  item-title="label"
                  item-value="value"
                  v-model="acLanguage"
              >
              </v-select>
              <v-row
                  class="ac-option-title"
                  align-content="center"
              >
                <v-checkbox
                    true-icon="custom:checkIcon"
                    false-icon="custom:uncheckIcon"
                    :label="$t('message.pageViewCharacterArticleSourceLabel')"
                    v-model="acSourceEnabled">
                </v-checkbox>
              </v-row>
              <v-row>
                <v-spacer/>
                <v-col
                >
                  <v-btn
                      :disabled="!acSourceEnabled"
                      id="pick_source"
                      variant="outlined"
                      style="text-align: center"
                      v-on:click="pickTrendingNews"
                  > {{ $t('message.pageViewCharacterPickSource')}}</v-btn>

                </v-col>
                <v-spacer/>
              </v-row>
              <v-row class="ac-source ac-source-title">
                <v-col>
                  <span> {{ acSourceTitle }}</span>
                </v-col>
              </v-row>
              <v-row class="ac-source ac-source-abstract">
                <v-col>
                  <span> {{ acSourceAbstract }}</span>
                </v-col>
              </v-row>
              <v-row
                  class="ac-option-title"
                  align-content="center"
              >
                <v-checkbox
                    true-icon="custom:checkIcon"
                    false-icon="custom:uncheckIcon"
                    :label="$t('message.pageViewCharacterArticleGuide')"
                    v-model="acGuideEnabled">
                </v-checkbox>
              </v-row>
              <v-text-field
                  :disabled="!acGuideEnabled"
                  v-model="acGuide">
              </v-text-field>
              <div
                  v-show="acType === 'audioblog'"
                >
                <v-row
                    class="ac-option-title"
                    align-content="center"
                >
                  <v-checkbox
                      true-icon="custom:checkIcon"
                      false-icon="custom:uncheckIcon"
                      :label="$t('message.pageViewCharacterArticleSongStyleLabel')"
                      v-model="acSongStyleEnabled">
                  </v-checkbox>
                </v-row>
                <v-combobox
                    :disabled="!acSongStyleEnabled"
                    :label="$t('message.pageViewCharacterArticleSongStyleHint')"
                    :items="this.topSongStyles"
                    item-title="label"
                    item-value="value"
                    v-model="acSongStyle"
                >
                </v-combobox>
                <v-radio-group
                    inline
                    :disabled="!this.acSongStyleEnabled"
                    v-model="this.acInstrumental"
                >
                  <v-radio
                      true-icon="custom:radioCheckIcon"
                      false-icon="custom:radioUncheckIcon"
                      :label="$t('message.pageViewCharacterArticleInstrumentalOnly')"
                      :value=true>
                  </v-radio>
                  <v-radio
                      true-icon="custom:radioCheckIcon"
                      false-icon="custom:radioUncheckIcon"
                      :label="$t('message.pageViewCharacterArticleWithLyrics')"
                      :value="false">
                  </v-radio>
                </v-radio-group>
                <v-row
                    class="ac-option-title"
                    align-content="center"
                >
                  <v-checkbox
                      :disabled="acInstrumental"
                      true-icon="custom:checkIcon"
                      false-icon="custom:uncheckIcon"
                      :label="$t('message.pageViewCharacterArticleLyrics')"
                      v-model="acLyricsEnabled">
                  </v-checkbox>
                </v-row>
                <v-text-field
                    :disabled="acInstrumental || !acLyricsEnabled"
                    v-model="acLyrics">
                </v-text-field>
              </div>
            </v-col>
          </v-card>
        </template>
      </v-dialog>
      <v-img
          class="portrait"
          :alt="this.authorPortraitPrompt"
          :src="this.authorPortrait"
          cover>
          <div
              v-show="isAdmin"
              class="regen-portrait-layout"
          >
            <v-btn
                v-if="portraitGenExecution == null"
                variant="plain"
                v-on:click="this.regeneratePortrait()"
            >
              <img src="@/assets/9035964_refresh_sharp_icon.svg" />
            </v-btn>
            <v-progress-circular
                v-else
                :size="25"
                :width="3"
                color="#ccc"
                indeterminate
            ></v-progress-circular>
          </div>


      </v-img>
      <div class="author-info">
        <p class="name">{{ this.authorName }}</p>
        <p class="occupation">{{ capitalize(this.authorOccupation) }}</p>
        <v-row
            align="center"
            justify="center"
            class="location">
          <v-col
              cols="1"
              md="1"
          >
            <country-flag :country='countryCode(this.authorCountry)' size='normal'></country-flag>
          </v-col>
          <v-col
              cols="8"
              md="8"
              class="d-flex align-items-center justify-start"
          >
            <span>{{ this.authorCountry + ", " + this.authorCity }}</span>
          </v-col>
          <v-spacer
              cols="3"
              md="3"
          ></v-spacer>
        </v-row>
        <div class="extra-info">
          <div v-show="this.authorExpertise != null">
            <span class="info-title"> {{ $t('message.pageViewCharacterExpertise') }}</span>
          </div>
          <span> {{ this.authorExpertise }}</span>
          <div v-show="this.authorInterests">
            <span class="info-title">{{ $t('message.pageViewCharacterInterests') }}</span>
          </div>
          <span> {{ this.authorInterests }}</span>
       </div>
      </div>
      <div class="news_frame" fluid>
        <v-row
            class="articles-heading"
            align="center"
            justify="center">
          <v-col
              cols="6"
              md="6">
            <div class="section-title">
              <p> {{ this.articlesSectionTitle}}</p>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
              cols="2"
              md="2"
          >
            <Schedules :id="this.authorId"></Schedules>
          </v-col>
          <v-col
              v-show="isAdmin"
              cols="2"
              md="2">
            <v-btn
                v-if="articleGenExecution == null"
                variant="plain"
                class="gen-article-btn"
                id="article_generation"
            >
              <img src="@/assets/8666806_edit_write_pen_icon.svg" />
            </v-btn>
            <v-progress-circular
                v-else
                :size="25"
                :width="3"
                indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-infinite-scroll
            :items="articles"
            :key="infiniteKey"
            @load="listArticles">
          <template v-slot:empty>
            <BottomLine/>
          </template>
          <v-list-item
              v-for="(article, i) in articles" :key="i"
              v-show="article.title !== null"
          >
            <div
                class="article-moments"
                v-if="article.type === 'moments'"
            >
              <div
                  class="article-moments-abstract"
                  v-on:click="viewArticle(article.id)">
                {{ article.abstract }}
              </div>
              <v-row
                  class="article-moments-images"
              >
                <v-col
                    class="article-moments-image"
                    v-for="(image, i) in articleImages(article)" :key="i"
                    v-show="image !== null"
                    :cols="articleImagesCols(article)"
                    :md="articleImagesCols(article)"
                >
                  <div class="shrink-image-container">
                    <img
                        class="article-moments-image shrink-on-hover"
                        :src="image" />
                  </div>
                </v-col>
              </v-row>
              <v-col class="providers">
                {{ getElapseTime(article.created) }}
              </v-col>
            </div>
            <v-row
                v-else
                align="start"
                justify="center">
              <v-col
                cols="5"
                md="5"
              >
                <div class="asset-image shrink-image-container">
                  <v-img class="shrink-on-hover"
                         :src="imageAsset(article.image)"
                         v-show="imageAsset(article.image) !== null"
                         cover
                  ></v-img>
                </div>
              </v-col>
              <v-col
                cols="7"
                md="7"
                class="article-title"
                v-on:click="viewArticle(article.id)"
              >
                {{article.title}}
                <v-col class="providers">
                  {{ getElapseTime(article.created) }}
                </v-col>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-list-item>
        </v-infinite-scroll>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import configs from "../configs";
import axios from 'axios'
import CountryFlag from 'vue-country-flag-next'
import {getCode} from "country-list";
import * as adminCommon from "@/common/admin";
import {computed, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import { ref } from 'vue';
import {capitalize} from "@/common/utils";
import Trending from "@/components/Trending.vue";
import { useI18n } from 'vue-i18n';
import Navigation from "@/components/Navigation.vue";
import store from "@/store";
import BottomLine from "@/components/BottomLine.vue";
import Schedules from "@/components/Schedules.vue";

export default {
  name: 'ViewCharacter',
  components: {Schedules, BottomLine, Navigation, Trending, CountryFlag},
  metaInfo() {
    return {
      title: this.$t('message.pageNewsAppName'),
    }
  },
  data() {
    return {
      fullPath: this.$route.fullPath,
      query: this.$route.query,
      theme: 'light',
      locale: 'en',
      docHeight: document.documentElement.clientHeight,
      displayHeight: document.documentElement.clientHeight,
      showFooter: true,
      errorMessage: null,
      style: '0',

      authorId: null,
      author: null,

      articles: [],
      pageSize: 5,
      pageKey: null,
      noMoreElements: false,

      portraitGenExecution: null,
      portraitGenCheckHandler: null,

      articleGenExecution: null,
      articleGenCheckHandler: null,

      executionCheckInterval: 10000,

      articleDialog: false,

      languages: [
        {
          label: this.$t("message.pageViewCharacterArticleLangEn"),
          value: "English",
        },
        {
          label: this.$t("message.pageViewCharacterArticleLangFr"),
          value: "French",
        },
        {
          label: this.$t("message.pageViewCharacterArticleLangSp"),
          value: "Spanish",
        },
        {
          label: this.$t("message.pageViewCharacterArticleLangZh"),
          value: "Chinese",
        },
      ],

      acTypes: [
        {
          label: this.$t("message.pageViewCharacterArticleTypeKnowledge"),
          value: "knowledge",
        },
        {
          label: this.$t("message.pageViewCharacterArticleTypeBlogpost"),
          value: "blogpost",
        },
        {
          label: this.$t("message.pageViewCharacterArticleTypeMoments"),
          value: "moments",
        },
        {
          label: this.$t("message.pageViewCharacterArticleTypeAlog"),
          value: "audioblog",
        },
        {
          label: this.$t("message.pageViewCharacterArticleTypeAppPromote"),
          value: "apppromote",
        }
      ],
    }
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();

    const token = computed(() => store.getters.token);
    console.log(`token: ${JSON.stringify(token.value)}`);
    const isAdmin = computed(() => store.getters.isAdmin);

    const acType = ref(sessionStorage.getItem('acType'));
    const acTypeEnabled = ref(acType.value != null);
    console.log(`acType: ${JSON.stringify(acType.value)}`);
    console.log(`acTypeEnabled: ${JSON.stringify(acTypeEnabled.value)}`);
    watch(acTypeEnabled, (enabled) => {
      if (enabled) {
        acType.value = "blogpost";
      } else {
        acType.value = null;
      }
    });

    const acLanguage = ref(sessionStorage.getItem('acLanguage'));
    const acLanguageEnabled = ref(acLanguage.value != null);
    console.log(`acLanguage: ${JSON.stringify(acLanguage.value)}`);
    console.log(`acLanguageEnabled: ${JSON.stringify(acLanguageEnabled.value)}`);
    watch(acLanguageEnabled, (enabled) => {
      if (enabled) {
        acLanguage.value = "English";
      } else {
        acLanguage.value = null;
      }
    });

    const acGuide = ref(sessionStorage.getItem('acGuide'));
    const acGuideEnabled = ref(acGuide.value != null);
    console.log(`acGuide: ${JSON.stringify(acGuide.value)}`);
    console.log(`acGuideEnabled: ${JSON.stringify(acGuideEnabled.value)}`);
    watch(acGuideEnabled, (enabled) => {
      if (enabled) {
        acGuide.value = null;
      } else {
        acGuide.value = null;
      }
    });

    const defaultTrendingNews = [
      {
        title: t("message.pageViewCharacterArticleSourceRandom"),
        id: "random"
      },
    ]

    const acSource = ref(sessionStorage.getItem('selectedId'));
    const acSourceTitle = ref(sessionStorage.getItem('selectedTitle'))
    const acSourceAbstract = ref(sessionStorage.getItem('selectedAbstract'))
    const acSourceEnabled = ref(acSource.value != null);
    console.log(`acSource: ${JSON.stringify(acSource.value)}`);
    console.log(`acSourceEnabled: ${JSON.stringify(acSourceEnabled.value)}`);
    console.log(`acSourceTitle: ${JSON.stringify(acSourceTitle.value)}`);
    console.log(`acSourceAbstract: ${JSON.stringify(acSourceAbstract.value)}`);
    watch(acSourceEnabled, (enabled) => {
      if (enabled) {
        acSource.value = defaultTrendingNews.id;
        acSourceTitle.value = defaultTrendingNews.title;
        acSourceAbstract.value = null;
      } else {
        acSource.value = null;
        acSourceTitle.value = null;
        acSourceAbstract.value = null;
      }
    });

    const acLyrics = ref(sessionStorage.getItem('acLyrics'));
    const acLyricsEnabled = ref(acLyrics.value != null);
    console.log(`acLyrics: ${JSON.stringify(acLyrics.value)}`);
    console.log(`acLyricsEnabled: ${JSON.stringify(acLyricsEnabled.value)}`);
    watch(acLyricsEnabled, (enabled) => {
      if (enabled) {
        acLyrics.value = null;
      } else {
        acLyrics.value = null;
      }
    });

    const acInstrumental = ref(sessionStorage.getItem('acInstrumental') === 'true' || false)
    console.log(`acInstrumental: ${JSON.stringify(acInstrumental.value)}`);
    watch(acInstrumental, (enabled) => {
      if (enabled) {
        acLyrics.value = null;
        acLyricsEnabled.value = false
      } else {
        acLyrics.value = null;
      }
    });

    const topSongStyles = [
      "Pop",
      "Traditional Folk",
      "Electronic Dance Music (EDM)",
      "Hip-Hop/Rap",
      "Afrobeat"
    ]
    const acSongStyle = ref(sessionStorage.getItem('acSongStyle'));
    const acSongStyleEnabled = ref(acSongStyle.value != null);
    console.log(`acSongStyle: ${JSON.stringify(acSongStyle.value)}`);
    console.log(`acSongStyleEnabled: ${JSON.stringify(acSongStyleEnabled.value)}`);
    watch(acSongStyleEnabled, (enabled) => {
      if (enabled) {
        acSongStyle.value = topSongStyles[0];
        acInstrumental.value = false;
      } else {
        acSongStyle.value = null;
        acInstrumental.value = null;
      }
    });

    const psfProp = sessionStorage.getItem('acPromoteSpecificFeature');
    const acPromoteSpecificFeature = ref(psfProp === 'true');
    console.log(`acPromoteSpecificFeature: ${JSON.stringify(acPromoteSpecificFeature.value)}`);

    const viewArticle = (articleId) => {
      router.push({
        path: `/view_article`,
        query: { id: articleId }
      });
    };

    const pickTrendingNews = () => {
      // Use router.push() to navigate
      console.log(`acType: ${acType.value}`)
      console.log(`acLanguage: ${acLanguage.value}`)
      console.log(`acGuide: ${acGuide.value}`)
      console.log(`acSongStyle: ${acSongStyle.value}`)
      console.log(`acInstrumental: ${acInstrumental.value}`)
      console.log(`acLyrics: ${acLyrics.value}`)

      sessionStorage.setItem('pickingSource', true)
      sessionStorage.setItem('redirect', route.fullPath)

      if (acType.value) {
        sessionStorage.setItem('acType', acType.value);
        if (acType.value === 'apppromote') {
          const acceptedProviders = [
              "Google Play",
          ]
          sessionStorage.setItem('acceptProviders',
              acceptedProviders.join(","));

          sessionStorage.setItem('acPromoteSpecificFeature', acPromoteSpecificFeature.value)
        } else if (acType.value === 'audioblog') {
          if (acSongStyle.value) {
            sessionStorage.setItem('acSongStyle', acSongStyle.value);
          } else {
            sessionStorage.removeItem('acSongStyle');
          }

          if (acInstrumental.value) {
            sessionStorage.setItem('acInstrumental', acInstrumental.value);
          } else {
            sessionStorage.removeItem('acInstrumental');
          }

          if (acLyrics.value) {
            sessionStorage.setItem('acLyrics', acLyrics.value);
          } else {
            sessionStorage.removeItem('acLyrics');
          }
        } else {
          sessionStorage.removeItem('acSongStyle');
          sessionStorage.removeItem('acInstrumental');
          sessionStorage.removeItem('acLyrics');

          sessionStorage.removeItem('acPromoteSpecificFeature');
          sessionStorage.removeItem('acceptProviders');
        }
      } else {
        sessionStorage.removeItem('acType');

        sessionStorage.removeItem('acSongStyle');
        sessionStorage.removeItem('acInstrumental');
        sessionStorage.removeItem('acLyrics');

        sessionStorage.removeItem('acPromoteSpecificFeature');
        sessionStorage.removeItem('acceptProviders');
      }

      if (acGuide.value) {
        sessionStorage.setItem('acGuide', acGuide.value);
      } else {
        sessionStorage.removeItem('acGuide');
      }

      if (acLanguage.value) {
        sessionStorage.setItem('acLanguage', acLanguage.value);
      } else {
        sessionStorage.removeItem('acLanguage');
      }

      sessionStorage.removeItem('selectedId')
      sessionStorage.removeItem('selectedTitle')
      sessionStorage.removeItem('selectedAbstract')

      router.push({
        path: `/trending`,
        query: {
          pickingSource: true
        }
      });
    };

    const infiniteKey = ref(0);

    const forceRender = () => {
      infiniteKey.value += 1;
    };


    return {
      token,
      isAdmin,
      viewArticle,
      pickTrendingNews,
      forceRender,
      infiniteKey,
      acType,
      acTypeEnabled,
      acGuide,
      acGuideEnabled,
      acLanguage,
      acLanguageEnabled,
      acSource,
      acSourceTitle,
      acSourceAbstract,
      acSourceEnabled,
      acSongStyle,
      acSongStyleEnabled,
      topSongStyles,
      acInstrumental,
      acLyrics,
      acLyricsEnabled,
      acPromoteSpecificFeature,
    }
  },

  watch: {
    theme(theme) {
      console.log(`theme changed: ${theme}`);
      document.documentElement.setAttribute(
          'theme', theme);
    },
    locale(loc) {
      console.log(`locale changed: ${loc}`);
      this.$i18n.locale = loc
    },
    displayHeight: function () {
      if (this.docHeight > this.displayHeight) {
        this.showFooter = false;
      } else {
        this.showFooter = true;
      }
    },
  },

  computed: {
    title: function () {
      if (this.article) {
        return this.article.title
      }

      return ""
    },

    articleType: function () {
      if (this.article) {
        return this.article.type
      }

      return ""
    },

    coverImage: function() {
      if (!this.article) {
        return ""
      }

      return this.imageAsset(this.article.image)
    },

    authorPortrait: function () {
      if (!this.author) {
        return ""
      }

      return this.imageAsset(this.author.portrait)
    },

    authorPortraitPrompt: function () {
      if (!this.author) {
        return ""
      }

      return this.author.portrait_prompt
    },

    authorName: function() {
      if (!this.author) {
        return ""
      }

      return this.author.display_name || this.author.name
    },

    authorInterests: function () {
      if (!this.author
          || !this.author.hobbies
          || this.author.hobbies.length === 0) {
        return null
      }

      return this.author.hobbies.map(capitalize).join(', ')
    },

    authorExpertise: function () {
      if (!this.author
          || !this.author.expertise
          || this.author.expertise.length === 0) {
        return null
      }

      return this.author.expertise.map(capitalize).join(', ')
    },

    articlesSectionTitle: function () {
      if (!this.author || !this.author.gender) {
        return this.$t('message.pageViewArticlesSectionTitle')
      }

      if (this.author.gender.toLowerCase() === 'male') {
        return this.$t('message.pageViewArticlesSectionTitleHis')
      } else {
        return this.$t('message.pageViewArticlesSectionTitleHer')
      }
    },

    keywords: function() {
      if (!this.article || !this.article.keywords) {
        return null
      }

      return this.article.keywords
    },

    authorCountry: function() {
      if (!this.author) {
        return ""
      }

      return this.author.country;
    },

    authorCity: function() {
      if (!this.author) {
        return ""
      }

      return this.author.city;
    },

    authorOccupation: function() {
      if (!this.author) {
        return ""
      }

      return this.author.occupation;
    },

    timeOfCreation: function() {
      if (!this.article) {
        return new Date();
      }

      return this.article.created
    },

    sections: function() {
      if (!this.article) {
        return ""
      }

      return this.article.sections;
    },
  },

  mounted: function () {
    console.log(`admin: ${JSON.stringify(this.isAdmin)}`);

    console.log(`locale: ${JSON.stringify(this.$route.query.locale)}`);
    console.log(`theme: ${JSON.stringify(this.$route.query.theme)}`);
    console.log(`author id: ${JSON.stringify(this.$route.query.id)}`);

    this.authorId = this.$route.query.id;

    if (this.query.theme) {
      this.theme = this.query.theme
    }

    if (this.query.locale) {
      this.locale=this.query.locale
    }

    if (this.query.style) {
      this.style=this.query.style
    }

    console.log(`[NS] picking Source: ${sessionStorage.getItem('pickingSource')}`)

    if (sessionStorage.getItem('pickingSource')) {
      this.articleDialog = true
    }
    // if (this.query.pickingSource) {
    // }

    window.onresize = () => {
      return (()=> {
        this.displayHeight = document.documentElement.clientHeight;
      })();
    }

    this.$el.querySelectorAll('input').forEach(function (element) {
      element.setAttribute('autocomplete', 'off');
    });

    this.retrieveAuthor()
    window.scrollTo(0, 0)
  },

  methods: {


    articleImages(article) {
      if (!article || !article.sections || article.sections.length === 0) {
        return [];
      }

      const text = article.sections[0].markdown

      const regex = /!\[.*?\]\((.*?)\)/g;
      let matches;
      const links = [];

      // Iterate over all matches
      while ((matches = regex.exec(text)) !== null) {
        links.push(matches[1]);
      }

      return links.length > 3 ? links.slice(0, 3) : links;
    },

    articleImagesCols(article) {
      const images = this.articleImages(article);
      if (!images || !images.length) {
        return 12;
      }

      /*const countOfImages = images.length;
      if (countOfImages % 3 === 0) {
        return 4
      } else if (countOfImages % 2 === 0) {
        return 6
      } else {
        return 12
      }*/

      return 4
    },

    capitalize(str) {
      if (!str) return str; // check for empty string and return it as is
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },

    countryCode(country) {
      if (country === 'Russia') {
        country = 'Russian Federation'
      } else if (country === 'United Kingdom') {
        country = 'United Kingdom of Great Britain and Northern Ireland'
      }
      return getCode(country)
    },

    searchKeyword(keyword) {
      const query = encodeURIComponent(keyword); // Encode the chip text to ensure the URL is valid
      const url = `https://www.google.com/search?q=${query}`;
      window.open(url, '_blank'); // Opens in a new tab
    },

    renderMarkdown(source) {
      return markdown.render(source)
    },

    imageAsset(src) {
      console.log(`src: ${src}`)
      let urlPrefix = configs.apiUrlPrefix;

      let image_src = src
      // console.log(`image src: ${image_src}`)
      if (image_src && !image_src.startsWith("http")) {
        image_src = urlPrefix + '/' + image_src;
      }
      // console.log(`refined image src: ${image_src}`)

      return image_src
    },

    correctUnicodeEscapes(text) {
      return text.replace(/\\u([\dA-Fa-f]{4})/g, (match, grp) => String.fromCharCode(parseInt(grp, 16)));
    },

    correctNewlines(text) {
      return text.replace(/\\n/g, "\n\n");
    },

    regeneratePortrait: async function() {
      console.log(`token: ${this.token}`)
      if (!this.isAdmin) {
        console.error(`no permit to run this in non-admin page.`)
        return;
      }

      this.cleanGenPortrait();

      let urlPrefix = configs.cloudFunctionPrefix;
      console.log(`urlPrefix: ${urlPrefix}`);

      let url = urlPrefix + `/v1/character/portrait?id=${this.authorId}`;
      console.log(`url: ${url}`);

      this.portraitGenExecution = "";

      try {
        let ret = await axios.patch(url, {}, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });

        if (ret && ret.data && ret.data.code === 200) {
          console.log(`[${this.authorId}]'s portrait is regenerated.`);

          this.portraitGenExecution = ret.data.execution;
          this.portraitGenCheckHandler = setInterval(
              this.checkExecution,
              this.executionCheckInterval,
              this.portraitGenExecution,
              this.genPortraitDone);
        } else {
          this.errorMessage = this.$t('message.errorFailedToCallApi');
          this.cleanGenPortrait();
        }
      } catch (e) {
        console.error(`failed to call regenerate portrait api: ${e}`);
        this.errorMessage = this.$t('message.errorFailedToCallApi');
        this.cleanGenPortrait();
      }
    },

    generateArticle: async function() {
      console.log(`token: ${this.token}`)
      if (!this.isAdmin) {
        console.error(`no permit to run this in non-admin page.`)
        return;
      }

      this.cleanGenArticle();

      let urlPrefix = configs.cloudFunctionPrefix;
      console.log(`urlPrefix: ${urlPrefix}`);

      let url = urlPrefix + `/v1/article/create`;
      console.log(`url: ${url}`);

      this.articleGenExecution = "";

      const data = {
        cid: this.authorId,
      }

      if (this.acType) {
        data.type = this.acType
      }

      if (this.acLanguage) {
        data.lang = this.acLanguage
      }

      if (this.acSource) {
        data.sid = this.acSource;
      }

      if (this.acGuide && this.acGuide.length > 0) {
        data.guide = this.acGuide
      }

      if (this.acSongStyle && this.acSongStyle.length > 0) {
        data.songStyle = this.acSongStyle
      }

      if (this.acInstrumental) {
        data.instrumental = this.acInstrumental
      }

      if (this.acLyrics && this.acLyrics.length > 0) {
        data.lyrics = this.acLyrics
      }

      if (this.acPromoteSpecificFeature) {
        data.promote_single_feature = true;
      }

      console.log(`data: ${JSON.stringify(data)}`);

      try {
        let ret = await axios.post(url, data, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });

        if (ret && ret.data && ret.data.code === 200) {
          console.log(`A new article is requested to character [${this.authorId}].`);

          this.articleGenExecution = ret.data.execution;
          if (this.articleGenExecution) {
            this.articleGenCheckHandler = setInterval(
                this.checkExecution,
                this.executionCheckInterval,
                this.articleGenExecution,
                this.genArticleDone);

            this.cleanGenArticleParameters();
          } else {
            this.genArticleDone(
                'succeed'
            );
          }
        } else {
          this.errorMessage = this.$t('message.errorFailedToCallApi');
          this.cleanGenArticle();
        }
      } catch (e) {
        console.error(`failed to call generate article api: ${e}`);
        this.errorMessage = this.$t('message.errorFailedToCallApi');
        this.cleanGenArticle();
      }
    },

    genArticleDone: function (status) {
      console.log(`article gen status: ${status}`);

      if (status === 'succeed') {
        this.articles = [];
        this.noMoreElements = false;
        this.pageKey = null;
        // this.$emit('load', {side: 'end'})
        this.forceRender()
      }

      this.cleanGenArticle()
    },

    genPortraitDone: function (status) {
      console.log(`portrait gen status: ${status}`);

      if (status === 'succeed') {
        this.retrieveAuthor()
      }

      this.cleanGenPortrait()
    },

    cleanGenArticle: function () {
      if (this.articleGenCheckHandler) {
        clearInterval(this.articleGenCheckHandler)
        this.articleGenCheckHandler = null;
      }

      this.articleGenExecution = null;
    },

    cleanGenArticleParameters: function () {
      this.acSource = null;
      this.acType = null;
      this.acGuide = null;
      this.acSourceTitle = null;
      this.acSourceAbstract = null;
      this.acLanguage = null;
      this.acInstrumental = null;
      this.acSongStyle = null;
      this.acLyrics = null;

      sessionStorage.removeItem('pickingSource');
      sessionStorage.removeItem('redirect');
      sessionStorage.removeItem('selectedId');
      sessionStorage.removeItem('selectedTitle');
      sessionStorage.removeItem('selectedAbstract');
      sessionStorage.removeItem('acLanguage');
      sessionStorage.removeItem('acType');
      sessionStorage.removeItem('acGuide');
      sessionStorage.removeItem('acInstrumental');
      sessionStorage.removeItem('acSongStyle');
      sessionStorage.removeItem('acLyrics');
      sessionStorage.removeItem('acceptProviders');
      sessionStorage.removeItem('acPromoteSpecificFeature');
    },

    cleanGenPortrait: function () {
      if (this.portraitGenCheckHandler) {
        clearInterval(this.portraitGenCheckHandler)
        this.portraitGenCheckHandler = null;
      }

      this.portraitGenExecution = null;
    },

    checkExecution: async function (executionId, done) {
      console.log(`checking status of execution [${executionId}].`);

      let urlPrefix = configs.cloudFunctionPrefix;
      console.log(`urlPrefix: ${urlPrefix}`);

      let url = urlPrefix + `/v1/execution/status?id=${executionId}`;
      console.log(`url: ${url}`);

      try {
        let ret = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });

        if (ret && ret.data && ret.data.code === 200) {
          console.log(`[${JSON.stringify(ret.data)}]`);
          if (ret.data.status === 'succeed' || ret.data.status === 'failed') {
            done(ret.data.status);
          }
        } else {
          this.errorMessage = this.$t('message.errorFailedToCallApi');
        }
      } catch (e) {
        console.error(`failed to call get execution status api: ${e}`);
        this.errorMessage = this.$t('message.errorFailedToCallApi');
      }
    },

    retrieveAuthor: async function () {
      console.log(`get author: id = ${this.author}`);
      if (!this.authorId) {
        return;
      }

      let urlPrefix = configs.apiUrlPrefix;
      console.log(`urlPrefix: ${urlPrefix}`);

      let url = urlPrefix + `/v1/creativepulse/character?id=${this.authorId}`;
      console.log(`url: ${url}`);

      try {
        let ret = await axios.get(url, {
        });

        if (ret && ret.data && ret.data.code === 200) {
          this.author = ret.data.author;
          console.log(`this.author : [${JSON.stringify(this.author)}]`);
        } else {
          this.errorMessage = this.$t('message.errorFailedToCallApi');
        }
      } catch (e) {
        console.error(`failed to call apply api: ${e}`);
        this.errorMessage = this.$t('message.errorFailedToCallApi');
      }
    },

    listArticles: async function ({ done }) {
      console.log(`list articles for author [${this.authorId}]: no more = ${this.noMoreElements}`);
      if (this.noMoreElements) {
        done('empty')
        return
      }
      let urlPrefix = configs.apiUrlPrefix;
      console.log(`urlPrefix: ${urlPrefix}`);

      let url = urlPrefix + `/v1/creativepulse/articles_by_author`;
      console.log(`url: ${url}`);

      url += `?page_size=${this.pageSize}`
      if (this.pageKey) {
        url += `&page_key=${this.pageKey}`
      }

      url += `&id=${this.authorId}`;

      try {
        let ret = await axios.get(url, {
        });

        if (ret && ret.data && ret.data.code === 200) {
          console.log(`ret: [${JSON.stringify(ret.data)}]`);

          for (let i = 0; i < ret.data.articles.length; i++){
            let item = ret.data.articles[i]
            item.expand = false
            this.articles.push(item)
          }

          this.pageKey = ret.data.nextPage
          if (ret.data.nextPage) {
            console.log('status: ok')
            done('ok')
          } else {
            console.log('status: empty')
            done('empty')
            this.noMoreElements = true
          }
        } else {
          this.errorMessage = this.$t('message.errorFailedToCallApi');
          done('error')
        }
      } catch (e) {
        console.error(`failed to call apply api: ${e}`);
        this.errorMessage = this.$t('message.errorFailedToCallApi');
        done('error')
      }
    },

    getElapseTime: function (publishedDateTime) {
      const publishedDate = new Date(publishedDateTime);

      // Get the current date and time
      const currentDate = new Date();

      // Calculate the time difference in milliseconds
      const timeDiff = currentDate.getTime() - publishedDate.getTime();

      // Convert the time difference to hours
      const hoursDiff = timeDiff / (1000 * 60 * 60);

      // Check if the elapsed time is less than 24 hours
      if (hoursDiff < 24) {
        // Round the elapsed time to the nearest hour and return as a string
        return Math.round(hoursDiff) + this.$t('message.pageArticlesTimeElapseHour');
      } else {
        // Calculate the elapsed time in days and return as a string
        const daysDiff = Math.floor(hoursDiff / 24);
        return daysDiff + this.$t('message.pageArticlesTimeElapseDay');
      }
    }
  }
}
</script>

<style scoped>
.v-application {
  padding: 16px;
}
.v-row {
  margin: 0;
}

.v-list {
  padding-top: 0;
}

.v-list-co
.v-list-item {
  padding: 0;
}

.article-title {
  text-align: left;
  font-size: 14px !important;
  font-weight: bold;
  overflow: hidden;
  white-space: normal;
  word-wrap: normal;
  padding: 12px 0;
}

.section {
  text-align: left;
  overflow: hidden;
  white-space: normal;
  word-wrap: normal;
}

.section-title {
  font-size: 18px !important;
  font-weight: bold;
  padding: 16px 0 4px;
}

.section-image {
  margin-top: 8px;
  margin-bottom: 8px;
}

.section-content {
  font-size: 15px !important;
}

.section-content span {
  display: block !important;
  padding-bottom: 8px;
}

.section-content-special span {
  font-size: 16px !important;
  font-style: italic;
  color: #6b6b6b;
  padding-top: 8px;
  padding-bottom: 8px;
}

.section-content-special .section-image,
.section-content-special .section-title {
  display: none;
}

.qa .section-image {
  display: block !important;
}
.qa .section-title {
  display: none;
}

.providers {
  font-size: 14px;
  text-align: left;
  font-weight: normal;
  color: grey;
  padding: 4px 0;
}

.article-moments .providers {
  padding: 0px 16px 8px;
}

.country {
  font-size: 14px;
  color: var(--primary-color);
}


.qa .top-illustration {
  display: none !important;
}

.author-info {
  padding: 8px 12px;
}

.author-info p {
  text-align: left;
}

.portrait {
  border-radius: 16px !important;
  height: 220px !important;
}

.portrait :deep(img:not(.v-btn img))   {
  object-position: 0 -20px;
}


.regen-portrait-layout {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
}

.regen-portrait-layout .v-btn{
  margin-left: -16px;
}

.author-info .name {
  text-align: left;
  font-size: 26px;
  font-weight: bold;
}

.author-info .occupation {
  font-size: 16px;
  color: gray;
  padding-left: 2px;
}


.author-info .location {
  font-size: 14px;
  padding: 6px 0 0 2px;
  margin-left: -4px;
}

.author-info .location div {
  padding: 0;
  margin: 0;
}

.author-info .location span {
  padding-left: 4px;
  padding-bottom: 0;
}

.flag {
  margin-left: -1em !important;
  margin-bottom: -1.1em !important;
  margin-top: -2.2em !important;
}


.v-list {
  padding-top: 0 !important;
}

.news_frame {
  padding: 0;
}

.v-infinite-scroll {
  margin-top: -16px;
  padding-bottom: 96px;
}

.news_frame list {

}

.news_frame .v-list-item {
  padding: 0 !important;
}

.news_frame .v-card {
  box-shadow: unset !important;
  border-radius: 0 !important;
}

.asset-image {
  margin: 0 4px;
  border-radius: 4px !important;
  object-fit: fill !important;
  height: 90px !important;
}

.v-card {
  max-width: 100% !important;
  margin-bottom: 10px;
}

.v-card-title {
  text-align: left;
  font-size: 20px !important;
  line-height: unset !important;
  font-weight: bold;
  overflow: hidden;
  white-space: normal;
  word-wrap: normal;
}


.v-card-subtitle {
  text-align: left;
  font-size: 12px !important;
  padding: 0 16px 8px 16px;
}

.v-card-text {
  font-size: 14px !important;
  text-align: left;
  padding-top: 0 !important;
}

.v-btn.v-size--default {
  color: var(--primary-color);
  font-size: 10px !important;
}

.section-title p {
  text-align: left;
  font-size: 20px !important;
  padding: 0 8px;
  color: #6b6b6b;
}

.extra-info {
  font-size: 14px;
  text-align: left;
}

.extra-info div {
  padding: 10px 0 0;
}

.extra-info .info-title {
  color: #444;
  font-size: 16px;
  font-weight: bold;
}

.gen-article-btn {
  padding: 0;
}

.articles-heading div {
  padding: 12px 4px 4px;
}

.ac-customization .v-input {
  margin: 0 16px;
}

.ac-option-title  :deep(.v-input__details) {
  display: none;
}

.ac-customization .v-checkbox :deep(.v-selection-control)  {
  min-height: 20px !important;
}

.ac-customization .v-slider {
  margin: 6px 36px !important;
}

.ac-option-title :deep(label) {
  color: black;
  opacity: unset;
  font-weight: bold;
  font-size: 18px !important;
}

.ac-option-title img {
  display: none;
  width: 32px;
  height: 32px;
}

.ac-source .v-col {
  padding: 8px 24px;
}
.ac-source-title {
  font-size: 16px;
  font-weight: bold;
}
.ac-source-abstract{
  font-size: 12px;

}

.article-moments {
  font-size: 14px !important;
  text-align: left;
}

.article-moments-abstract {
  padding: 8px 16px;
}

.article-moments-images {
  margin: 0;
  padding: 0 14px;
}

.article-moments-image {
  padding: 2px;
}

.article-moments-image {
  border-radius: 8px !important;
  object-fit: fill !important;
}

.shrink-image-container {
  overflow: hidden; /* Ensure that the image is clipped when it enlarges */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px  !important;
}

.shrink-on-hover {
  border-radius: 4px  !important;
  width: 100%; /* Ensure the image fits the container initially */
  height: 100%;
  transform: scale(1.2); /* Shrink the image */
  transition: transform 0.8s ease; /* Smooth transition */
}

.shrink-on-hover:hover {
  transform: scale(1); /* Shrink the image */
}
</style>
