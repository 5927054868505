export function getLastPathSegment(url) {
    try {
        const parsedUrl = new URL(url);
        const pathname = parsedUrl.pathname;
        const segments = pathname.split('/');
        return segments.pop() || segments.pop(); // Handle trailing slash
    } catch (error) {
        console.error('Invalid URL', error);
        return '';
    }
}

export function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function parseTimeString(timeString) {
    console.log(`parseTimeString: ${timeString}`);
    // Split the time string into components
    let [time, period] = timeString.split(' ');
    let [hour, minute] = time.split(':').map(Number);

    // Convert hour based on AM/PM
    if (period === 'PM' && hour !== 12) {
        hour += 12;
    } else if (period === 'AM' && hour === 12) {
        hour = 0;
    }

    // Create a new Date object
    let currentTime = new Date();
    currentTime.setHours(hour);
    currentTime.setMinutes(minute);
    currentTime.setSeconds(0);
    currentTime.setMilliseconds(0);

    return currentTime;
}
